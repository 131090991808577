import React, { useState, useEffect } from 'react';
import {Button, Form, Space, message, Spin, Empty, Row, Col} from 'antd';
import {EditorState, convertToRaw, RichUtils, convertFromRaw} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../article/add/Add.css';
import { useSelector } from "react-redux";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {useNavigate, useParams} from "react-router-dom";
import {useRole, useTitle} from "../../../components/hook/index";
import BreadCom from "../../../components/breadCom/BreadCom";
import {toastClick} from "../../../components/toast/CustomToast";
import {uploadCallback} from "../../../components/utils/Constantes";


export default function UpdateAPropos() {
    const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
);
    useRole(['ROOT','SUPERADMIN']);
    useTitle('MAJ ');
    const {accessToken} = useSelector(state => state.authUser);
    const params = useParams();
    const nav = useNavigate();
    const [aPropos, setAPropos] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigation = (link)=>{
        setLoading(true);
        nav(link);
        setLoading(false);
    }

    const getAPropos = () => {
        axiosInstance().get(
            "api/apropos/list"
        ).then((result)=> {
            if(result.data != null){
                setAPropos(result.data)
                setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(result.data.contenu))));
            }
            setLoading(false);
        }).catch((result)=> {
            setLoading(false);
        });
    }
    useEffect(
        ()=>{
            setLoading(true)
            getAPropos();
        },[]
    )
    const onUpdate = async (values) => {
        if(editorState.getCurrentContent().getPlainText().trim().length!=0){
            setLoading(true);
            const dat ={
                "id":aPropos.id,
                "contenu":JSON.stringify(convertToRaw(editorState.getCurrentContent())),
            }
            axiosInstance(accessToken).put(
                "api/apropos/update/"+aPropos.id,
                dat
            ).then((result)=> {
                toastClick("Done ...","success");
                navigation('/admin/apropos');
                setLoading(false);
            }).catch((result)=>{
                setLoading(false);
                toastClick(result.response.data.error,"error");
            });
        }else{
            toastClick("Le contenu est vide","info");
        }
    }
    const returnValue = aPropos ==null ? <>
        <Spin spinning={loading}>
            <Empty />
        </Spin>
    </>  : <>
        <Spin  spinning={loading}>

            <div  style={{background: '#ffffff00'}}>
                <Row >
                    <Col>

                        <Row style={{marginTop: '15px'}}>
                            <Col >
                                <Form onFinish={onUpdate} >
                                    <Form.Item
                                        label="Contenu"
                                        name="contenu">
                                        <Editor
                                            editorState={editorState}
                                            onEditorStateChange={setEditorState}
                                            toolbar={
                                                {
                                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history'],
                                                    inline: {
                                                        inDropdown: false,
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
                                                        bold: {className: undefined},
                                                        italic: {className: undefined},
                                                        underline: {className: undefined},
                                                        strikethrough: {className: undefined},
                                                        monospace: {className: undefined},
                                                        superscript: {className: undefined},
                                                        subscript: {className: undefined},
                                                    },
                                                    blockType: {
                                                        inDropdown: true,
                                                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                    },
                                                    fontSize: {
                                                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                    },
                                                    fontFamily: {
                                                        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                    },
                                                    list: {
                                                        inDropdown: false,
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                        options: ['unordered', 'ordered', 'indent', 'outdent'],
                                                        unordered: {className: undefined},
                                                        ordered: {className: undefined},
                                                        indent: {className: undefined},
                                                        outdent: {className: undefined},
                                                    },
                                                    textAlign: {
                                                        inDropdown: false,
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                        options: ['left', 'center', 'right', 'justify'],
                                                        left: {className: undefined},
                                                        center: {className: undefined},
                                                        right: {className: undefined},
                                                        justify: {className: undefined},
                                                    },
                                                    colorPicker: {
                                                        className: undefined,
                                                        component: undefined,
                                                        popupClassName: undefined,
                                                        colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                                                            'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                                                            'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                                                            'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                                                            'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                                                            'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                                                    },
                                                    link: {
                                                        inDropdown: false,
                                                        className: undefined,
                                                        component: undefined,
                                                        popupClassName: undefined,
                                                        dropdownClassName: undefined,
                                                        showOpenOptionOnHover: true,
                                                        defaultTargetOption: '_self',
                                                        options: ['link', 'unlink'],
                                                        link: {className: undefined},
                                                        unlink: {className: undefined},
                                                        linkCallback: undefined
                                                    },
                                                    emoji: {
                                                        className: undefined,
                                                        component: undefined,
                                                        popupClassName: undefined,
                                                        emojis: [
                                                            '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                                            '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                                            '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                                            '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                                            '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                                            '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                                            '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                                            '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                                            '✅', '❎', '💯',
                                                        ],
                                                    },
                                                    image: {
                                                        className: undefined,
                                                        component: undefined,
                                                        popupClassName: undefined,
                                                        urlEnabled: true,
                                                        uploadEnabled: true,
                                                        alignmentEnabled: true,
                                                        uploadCallback: uploadCallback,
                                                        previewImage: true,
                                                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                        alt: {present: false, mandatory: false},
                                                        defaultSize: {
                                                            height: 'auto',
                                                            width: 'auto',
                                                        },
                                                    },
                                                    remove: {className: undefined, component: undefined},
                                                    history: {
                                                        inDropdown: false,
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                        options: ['undo', 'redo'],
                                                        undo: {className: undefined},
                                                        redo: {className: undefined},
                                                    },
                                                }
                                            }
                                            wrapperClassName="wrapper-class"
                                            editorClassName="editor-class"
                                            toolbarClassName="toolbar-class"
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        <Space style={{float:'right'}}>
                                            <Button type='primary' htmlType='submit' disabled={loading}>
                                                Submit
                                            </Button>
                                            <Button danger  disabled={loading}  onClick={() => navigation('/admin/apropos')} >
                                                <i className="fa fa-times" aria-hidden="true"></i> cancel
                                            </Button>

                                        </Space>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <div>
                </div>


            </div>
        </Spin>
    </>
    const breadCum = [
        {
            id:0,
            last:false,
            path:'/admin/apropos',
            name:'A Propos',
        },
        {
            id:1,
            last:true,
            path:params.id,
            name:params.id,
        }
    ];
    return (
        <>
            <BreadCom name={"A Propos"} data={breadCum}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Update
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0" style={
                                        {
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }
                                    }>
                                        {returnValue}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );





}