import React, {useContext, useEffect, useState} from "react";
import {Button, Space, Tag, Tooltip} from "antd";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {useRole, useTitle} from "../../../components/hook";
import {EditFilled, InfoCircleFilled} from "@ant-design/icons";
import BreadCom from "../../../components/breadCom/BreadCom";
import TableTemplate from "../../../components/table/index";
import AddGroup from "../add/AddGroup";
import {LoadingContext} from "../../../context/LoadingContext";
import {constantes} from "../../../components/utils/Constantes";
import LoadingPage from "../../../components/loadingPage/LoadingPage";
import ViewTable from "../../../components/table/viewTable";

export default function ListGroup() {
    const {accessToken} = useSelector(state => state.authUser);
    const {loading, setLoading} = useContext(LoadingContext)
    const [pageable, setPageable] = useState(constantes);
    const [url, setUrl] = useState('api/groupes/list?page=');
    const [data, setData] = useState([]);
    const nav = useNavigate();
    useRole(['ROOT','SUPERADMIN']);
    useTitle('Liste des Groupes');
    const navigate = (link) => {
        nav(link);
    }
    const fetchGroupes = (page=0) => {
        setLoading(true);
        axiosInstance(accessToken).get(url+page).then(
            (result) => {
                if(result.data.totalElements == 0){
                    setPageable(constantes);
                    setData([]);
                }else {
                    setData(result.data.content);
                    setPageable({
                        pageable: result.data.pageable,
                        last: result.data.last,
                        totalElements: result.data.totalElements,
                        totalPages: result.data.totalPages,
                        size: result.data.size,
                        number: result.data.number,
                        first: result.data.first,
                        numberOfElements: result.data.numberOfElements,
                        empty: result.data.empty
                    });
                }
                setLoading(false);
            }).catch(
            (error)=>{
                setData([]);
                setPageable(constantes);
                setLoading(false);
        });
    }
    useEffect(() => {
        fetchGroupes(0);
    }, []);
    const breadCum = [
        {
            id:0,
            last:true,
            path:'Groupes',
            name:'Groupes',
        }
    ];
    const columns = [
        {
            accessor: "info",
        },
        {
            accessor: "Name",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "Libelle",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "action",
        },
    ];
    const renderRow = (item) => {
        const classHover = "border-b border-gray-200 even:bg-slate-50 text-sm hover:bg-lamaYellow";
        return (
            <tr
                key={item.id}
                className={classHover}
            >
                <td className="flex items-center gap-4 p-4">
                    <div className="flex flex-col">
                        <h3 className="font-semibold">{item.id}</h3>
                        {/*<p className="text-xs text-gray-500">{item?.email}</p>*/}
                    </div>
                </td>
                <td className="hidden md:table-cell">{item.name}</td>
                <td className="hidden md:table-cell">{item.libelle}</td>
                <td>
                    <div className="flex items-center gap-2">
                        <Space>
                            <Tooltip title={"Detail sur le groupe"}>
                                <Button type="primary" shape="circle"
                                        onClick={() => navigate('/admin/group/' + item.id)}>
                                    <i aria-hidden="true"><InfoCircleFilled /></i>
                                </Button>
                            </Tooltip>
                            <Tooltip title={"Mise a jour du groupe"}>
                                <Button type="primary" shape="circle"
                                        onClick={() => navigate('/admin/group/update/' + item.id)}>
                                    <i aria-hidden="true"><EditFilled /></i>
                                </Button>
                            </Tooltip>
                        </Space>
                    </div>
                </td>
            </tr>
        );
    }

    return(
        <>
            <BreadCom name={"Liste des Groupes"} data={breadCum}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Liste des Groupes
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        {
                                            loading ? <LoadingPage /> : <>
                                                <ViewTable data={data} pageable={pageable} setData={setData} url={url} setPageable={setPageable} renderRow={renderRow} columns={columns} addBTN={<AddGroup />} />
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}