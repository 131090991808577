import React, {useContext, useState} from "react";
import {LoadingContext} from "../../context/LoadingContext";
import {Divider} from "antd";
import Tableau from "../Tableau";
import Pagination from "../Pagination";

export default function ViewTable({
                                      addBTN,
                                      columns=[],
                                      renderRow,
                                      data=[],
                                      pageable,
                                      setData,
                                      setPageable,
                                      url}){

    const {loading} = useContext(LoadingContext);
    const [spinner, setSpinner]=useState(loading);

    return(
        <>
            {addBTN}
            <Divider />
            <Tableau columns={columns} renderRow={renderRow} data={data} spinner={spinner} />
            {
                pageable.totalElements > 0 && <Pagination pageable={pageable} setData={setData} setPageable={setPageable} url={url} loading={spinner} setLoading={setSpinner} />
            }
        </>
    );

}