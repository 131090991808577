import * as Utils from '../utils/Constantes';
import {compare} from "../utils/Constantes";
export default function BreadCom({name, data=[]}){
    const basePath = '';//Utils.BASE+Utils.ADMIN+Utils.BASE;

    return(
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">{name.toUpperCase()}</h1>
                    </div>
                    {
                        (data.length != 0) && <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                {
                                    data.sort(compare).map((value, index) => {
                                        if(value.last){
                                            return <li key={index} className="breadcrumb-item active">{value.name.toString().toUpperCase()}</li>;
                                        }else {
                                            return <li key={index} className="breadcrumb-item"><a href={basePath+value.path}>{value.name}</a></li>;
                                        }
                                    })
                                }
                            </ol>
                        </div>
                    }

                </div>
            </div>
        </div>
    );
}