const BASE='/admin/';
export const DASHBOARDSHORT='dashboard';
export const DASHBOARD=BASE+DASHBOARDSHORT;
export const PROFILE=BASE+'profile';

export const QRCODE=BASE+'qrcode';
export const PARAMETRE=BASE+'parametre';
export const USER=BASE+'user';
export const DENONCIATION=BASE+'denonciation';
export const GROUPE=BASE+'group';
export const ARTICLE=BASE+'article';
export const TEMOIGNAGE=BASE+'temoignage';
export const CP=BASE+'centrepartenaire';
export const MISSION=BASE+'mission';
export const VALEUR=BASE+'valeur';
export const QUIZZ=BASE+'quizz';
export const ELEARNING=BASE+'formation';
export const THEME=BASE+'theme';
export const APROPOS=BASE+'apropos';
export const CERTIFICAT=BASE+'certificate';
export const SLIDERS=BASE+'sliders';
export const UNAUTHORIZE=BASE+'unauthorize';

export const TRADUCTION={fr:'fr', en:'en'};

export const compare = (a,b)=>{
    if(a.id<b.id)
        return -1;
    if(a.id>b.id)
        return 1;
    return 0;
}
export const constantes = {
    pageable:null,
    last: false,
    totalElements: 0,
    totalPages: 0,
    size: 0,
    number: 0,
    first: false,
    numberOfElements: 0,
    empty: false
};

export const uploadCallback = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            resolve({ data: { link: e.target.result } }); // URL de l'image
        };
        reader.onerror = (e) => reject(e);
        reader.readAsDataURL(file);
    });
};