//import { ITEM_PER_PAGE } from "../utils/settings";

import axiosInstance from "../configurations/axiosInstance/AxiosInstance";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {constantes} from "./utils/Constantes";

const Pagination = ({pageable, setData,setPageable, url, setLoading, loading}) => {
    const {accessToken} = useSelector(state => state.authUser);
    const changePage = (newPage) => {
        fetchData(newPage);
    };
    const fetchData = (page=0)=>{
        setLoading(true);
        axiosInstance(accessToken).get(
            url+page
        ).then((result)=> {
            setData(result.data.content);
            setPageable({
                pageable: result.data.pageable,
                last: result.data.last,
                totalElements: result.data.totalElements,
                totalPages: result.data.totalPages,
                size: result.data.size,
                number: result.data.number,
                first: result.data.first,
                numberOfElements: result.data.numberOfElements,
                empty: result.data.empty
            });
            setLoading(false);
        }).catch((result)=> {
            setData([]);
            setPageable(constantes);
            setLoading(false);
        });
    }
    return (
        <>
            {
                !loading && <>
                    {
                        !pageable?.empty &&  <div className="p-4 flex items-center justify-between text-gray-500">
                            <button
                                disabled={pageable?.first}
                                className="py-2 px-4 rounded-md bg-slate-200 text-xs font-semibold disabled:opacity-50 disabled:cursor-not-allowed"
                                onClick={() => {
                                    changePage(pageable?.pageable?.pageNumber - 1);
                                }}>
                                Preview
                                {/*<FormattedMessage id="preview" />*/}
                            </button>
                            <div className="flex items-center gap-2 text-sm">
                                <button
                                    style={
                                        {
                                            cursor: "default"
                                        }
                                    }
                                    className={`px-2 rounded-sm bg-lamaSky`}
                                >
                                    {pageable?.pageable?.pageNumber+1}/{pageable?.totalPages}
                                </button>
                            </div>
                            <button
                                className="py-2 px-4 rounded-md bg-slate-200 text-xs font-semibold disabled:opacity-50 disabled:cursor-not-allowed"
                                disabled={pageable?.last}
                                onClick={() => {
                                    changePage(pageable?.pageable?.pageNumber + 1);
                                }}
                            >
                                Next
                                {/*<FormattedMessage id="next" />*/}
                            </button>
                        </div>
                    }
                </>
            }
        </>
    );
};

export default Pagination;