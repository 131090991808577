import {useSelector} from "react-redux";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Space, Tag, Tooltip} from "antd";
import {LoadingContext} from "../../../context/LoadingContext";
import {constantes} from "../../../components/utils/Constantes";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {EditFilled, InfoCircleFilled} from "@ant-design/icons";
import {Active} from "../../../components/active";
import BreadCom from "../../../components/breadCom/BreadCom";
import LoadingPage from "../../../components/loadingPage/LoadingPage";
import ViewTable from "../../../components/table/viewTable";
import {fabric} from "fabric";

export default function ListCertificate() {
    const canvasRef = useRef(null);
    const {accessToken} = useSelector(state => state.authUser);
    const {loading, setLoading} = useContext(LoadingContext)
    const [pageable, setPageable] = useState(constantes);
    const [url, setUrl] = useState('api/certificate');
    const [data, setData] = useState([]);
    const nav = useNavigate();
    const items = [];
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('Liste des Certificats');
    const navigate= (link)=>{
        nav(link);
    }
    const getData = (page=0)=>{
        setLoading(true);
        axiosInstance(accessToken).get(url+'?page='+page).then(
            (result)=>{
                if(result.data.totalElements == 0){
                    setPageable(constantes);
                    setData([]);
                }else {
                    setData(result.data.content);
                    setPageable({
                        pageable: result.data.pageable,
                        last: result.data.last,
                        totalElements: result.data.totalElements,
                        totalPages: result.data.totalPages,
                        size: result.data.size,
                        number: result.data.number,
                        first: result.data.first,
                        numberOfElements: result.data.numberOfElements,
                        empty: result.data.empty
                    });
                }
                setLoading(false);
            }).catch(
            (error)=>{
                setData([]);
                setPageable(constantes);
                setLoading(false);
            }
        );
    }
    useEffect(() => {
        getData(0);
    }, []);

    const viewImage = (item) => {
        const canvas = new fabric.Canvas(canvasRef.current, {
            width: 841,
            height: 595,
            backgroundColor: '#f0f0f0',
            selection:true,
        });
        canvas.loadFromJSON(item.contenu, () => {
            canvas.renderAll();
            if (canvas) {
                const dataURL = canvas.toDataURL({ format: 'png' });
                const link = document.createElement('a');
                link.href = dataURL;
                link.download = 'certification.png';
                link.click();
            }
        });
    };

    const breadCum = [
        {
            id:0,
            last:true,
            path:'Certificats',
            name:'Certificats',
        }
    ];
    const columns = [
        {
            accessor: "info",
        },
        {
            accessor: "Name",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "Status",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "action",
        },
    ];
    const renderRow = (item) => {
        const classHover = "border-b border-gray-200 even:bg-slate-50 text-sm hover:bg-lamaYellow";
        let result = null;
        if(item.status){
            result = (
                <Tag color="green" >
                    Activer
                </Tag>
            )
        }else{
            result = (
                <Tag color="volcano">
                    Desactiver
                </Tag>
            )
        }
        return (
            <tr
                key={item.id}
                className={classHover}
            >
                <td className="flex items-center gap-4 p-4">
                    <div className="flex flex-col">
                        <h3 className="font-semibold">{item.id}</h3>
                        {/*<p className="text-xs text-gray-500">{item?.email}</p>*/}
                    </div>
                </td>
                <td>
                    <div className="flex items-center gap-2">
                        {item.name}
                    </div>
                </td>
                <td>
                    <div className="flex items-center gap-2">
                        {result}
                    </div>
                </td>
                <td>
                    <div className="flex items-center gap-2">
                        <Space>
                            <Tooltip title={"Detail sur le certificat"}>
                                <Button type="primary" shape="circle"  onClick={() => viewImage(item)} >
                                    <i aria-hidden="true"><InfoCircleFilled /></i>
                                </Button>
                            </Tooltip>
                            <Tooltip title={"Mise a jour du certificat"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/certificate/update/'+item.id)} >
                                    <i aria-hidden="true"><EditFilled /></i>
                                </Button>
                            </Tooltip>
                            <Active status={item.status} path={"api/certificate/change_status/"} id={item.id}/>
                        </Space>
                    </div>
                </td>
            </tr>
        );
    }

    return(
        <>
            <BreadCom name={"Liste des Certificats"} data={breadCum}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Liste des Certificats
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        {
                                            loading ? <LoadingPage /> : <>
                                                <ViewTable data={data} pageable={pageable} setData={setData} url={url+'?page='} setPageable={setPageable} renderRow={renderRow} columns={columns} addBTN={<Button href={"/admin/certificate/ajouter"}>Ajouter un certificat</Button>} />
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );

}