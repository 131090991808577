import React, {useEffect, useState} from 'react';
import {Button, Form, Input, message, Modal, Select, Space, Spin} from 'antd';
import { useSelector } from "react-redux";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {EditOutlined, PlusCircleFilled} from "@ant-design/icons";
import {useRole} from "../../../components/hook";
import {useParams} from "react-router-dom";
import BreadCom from "../../../components/breadCom/BreadCom";
import {toastClick} from "../../../components/toast/CustomToast";

export default function SetResponseOfQuestion() {
    const {accessToken} = useSelector(state => state.authUser);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const params = useParams();
    const [questionList, setQuestionList] = useState([]);
    const [question, setQuestion] = useState(null);
    const [questionS, setQuestionS] = useState([]);
    const [responses, setResponses] = useState([]);

    const breadCum = [
        {
            id:0,
            last:false,
            path:'/admin/quizz',
            name:'Quizz',
        },
        {
            id:1,
            last:true,
            path:params.id,
            name:params.id,
        }
    ];
    useRole(['ROOT','SUPERADMIN']);

    const onSubmit = (values) => {
        setLoading(true);
        let dat = values;

        axiosInstance(accessToken).post(
            "test/api/sondage/question_response",
            dat,
        ).then(
            (result) => {
                message.success('Success');
                form.resetFields();
                window.location.reload();
                setLoading(false);
            }).catch((result) => {
            message.error(result.response.data.message);
            setLoading(false);
        });
    };
    const getSondageById = () => {
        setLoading(true);
        axiosInstance(accessToken).get('test/api/sondage/get/'+parseInt(params.id))
            .then(
                (response)=>{
                    const data = response.data;
                    setQuestionList(data.questions);
                    const options = [];
                    data.questions.map((value, index) => {
                        if(value.score===0){
                            options.push({
                                value: value.id,
                                label: value.libelle,
                            })
                        }else {
                            let canPush = true;
                            value.modelResponses.map(value1 => {
                                if(value.score===value1.score) {
                                    canPush = false;
                                }
                            }) ;
                            if(canPush){
                                options.push({
                                    value: value.id,
                                    label: value.libelle,
                                })
                            }
                        }
                    });
                    setQuestionS(options);
                    if(options.length === 0){
                        setLoading(false);
                        window.location.href='/admin/quizz';
                    }
                    setLoading(false);
                }
            ).catch(
            (error)=>{
                toastClick(error.response.data.message,'error');
                setLoading(false);
            }
        )
    }
    useEffect(()=>{
        getSondageById()
    },[])

    const setResponseSelect = (event) =>{
        setResponses([]);
        const data = questionList.filter(value => value.id==event);
        if(data.length < 1) {
            return [];
            setQuestion(null);
        }else {
            const resp = [];
           data[0].modelResponses.map(value => {
               resp.push({
                   value: value.id,
                   label: value.value,
               });

           })
            setResponses(resp);
           setQuestion(data[0]);
        }
    }
    const setScore = (event) =>{
        form.setFieldsValue({
                score: question.score,
        })
    }
    return(
        <>
            <BreadCom name={"Sondage info"} data={breadCum}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Detail
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <Spin spinning={loading}>
                                            <Form onFinish={onSubmit} form={form}>
                                                <Form.Item
                                                    label="Question"
                                                    name="questionId"
                                                    rules={
                                                        [
                                                            { required: true, message: 'Veuillez selectionner la question' }
                                                        ]}
                                                    hasFeedback>
                                                    <Select
                                                        showSearch
                                                        optionFilterProp="label"
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        }
                                                        placeholder="Choisir la question"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        onChange={setResponseSelect}
                                                        options={questionS}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Reponse"
                                                    name="responseId"
                                                    rules={
                                                        [
                                                            { required: true, message: 'Veuillez selectionner la response' }
                                                        ]}
                                                    hasFeedback>
                                                    <Select
                                                        showSearch
                                                        optionFilterProp="label"
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        }
                                                        placeholder="Choisir la bonne reponse"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        onChange={setScore}
                                                        options={responses}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Score"
                                                    name="score"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Veuillez entrer le score de la question'
                                                        },
                                                        {
                                                            pattern: /^\d+$/, // Regex pour valider uniquement les entiers naturels
                                                            message: 'Veuillez entrer le score de la question.'
                                                        }
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Input placeholder="Veuillez entrer le score de la question" />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Space style={{float:'right'}}>
                                                        <Button type='primary' htmlType='submit' disabled={loading}>
                                                            Submit
                                                        </Button>
                                                    </Space>
                                                </Form.Item>
                                            </Form>
                                        </Spin>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}