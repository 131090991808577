import {Link, Outlet, useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Empty, Form, Input, message, Row, Select, Space, Spin, Tag} from "antd";
import {useRole, useTitle} from "../../../components/hook/index";
import {useSelector} from "react-redux";
import BreadCom from "../../../components/breadCom/BreadCom";

export default function UpdateMission() {
    const {accessToken} = useSelector(state => state.authUser);
    const params = useParams();
    const nav = useNavigate();
    const [form] = Form.useForm();
    const [mission, setMission] = useState(null);
    const [loading, setLoading] = useState(true);
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('MAJ de la Mission');

    const breadCum = [
        {
            id:0,
            last:false,
            path:'/admin/mission',
            name:'Missions',
        },
        {
            id:1,
            last:true,
            path:params.id,
            name:params.id,
        }
    ];
    const navigation = (link)=>{
        setLoading(true);
        nav(link);
        setLoading(false);
    }
    const onUpdate = (values) => {
        setLoading(true);
        const dat =values;
        axiosInstance(accessToken).put(
            "api/missions/"+mission.id,
            dat,
        ).then(
            (result)=> {
                message.success('Mission '+dat.title+' Update');
                form.resetFields();
                navigation('/admin/mission');
                setLoading(false);
            }).catch((result)=> {
            message.error(result.response.data.message);
            setLoading(false);
        });
    }
    const getMissionById = () => {
        axiosInstance(accessToken).get('api/missions/find-by-id/'+parseInt(params.id))
            .then(
                (response)=>{
                    setMission(response.data);
                    setLoading(false);
                }
            ).catch(
            (error)=>{
                message.error(error.response.data.message);
                setLoading(false);
            }
        )
    }

    useEffect(
        ()=>{
            setLoading(true)
            getMissionById();
        },[]
    );
    const returnValue = mission ==null ? <>
        <Spin spinning={loading}>
            <Empty />
        </Spin>
    </>  : <>
        <Spin  spinning={loading}>

            <div style={{background: '#ffffff00'}}>
                <Row >
                    <Col span={12} >

                        <Row style={{marginTop: '15px'}}>
                            <Col >
                                <Form onFinish={onUpdate} form={form} initialValues={mission}>
                                    <Form.Item
                                        label="Titre"
                                        name="title"
                                        rules={
                                            [
                                                { required: true, message: 'Veuillez entrer le Titre' },
                                                {type:"string", message:"Entrer un titre valide !"}
                                            ]}
                                        hasFeedback>
                                        <Input placeholder="entrer le Titre"/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Description"
                                        name="description"
                                        rules={
                                            [
                                                { required: true, message: 'Veuillez entrer la description' },
                                                {type:"string", message:"Entrer une description !"}
                                            ]}
                                        hasFeedback>
                                        <Input.TextArea row={4} placeholder="entrer le Prenom"></Input.TextArea>
                                    </Form.Item>
                                    <Form.Item>
                                        <Space style={{float:'right'}}>
                                            <Button type='primary' htmlType='submit' disabled={loading}>
                                                Submit
                                            </Button>
                                            <Button danger  disabled={loading}  onClick={() => navigation('/admin/mission')} >
                                                <i className="fa fa-times" aria-hidden="true"></i> cancel
                                            </Button>

                                        </Space>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <div>
                    <Outlet />
                </div>


            </div>
        </Spin>
    </>

    return (
        <>
            <BreadCom name={"Mission update"} data={breadCum}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Update
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0" style={
                                        {
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }
                                    }>
                                        {returnValue}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}