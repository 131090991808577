import { IntlProvider } from 'react-intl';
import messages_en from '../../i18n/en.json';
import messages_fr from '../../i18n/fr.json';
import {useContext} from "react";
import {LanguageContext} from "../../context/LanguageContext";

const messages = {
    en: messages_en,
    fr: messages_fr
};

export function IntlManager({ children }) {
    const { langue } =useContext(LanguageContext);
    return (
        <IntlProvider locale={langue} messages={messages[langue]}>
            {children}
        </IntlProvider>
    );
}