import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Empty, Form, Input, message, Row, Select, Space, Spin} from "antd";
import {useSelector} from "react-redux";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import BreadCom from "../../../components/breadCom/BreadCom";

export default function UpdateUser() {
    const {accessToken} = useSelector(state => state.authUser);
    const params = useParams();
    const nav = useNavigate();
    const [form] = Form.useForm();
    const [groupList, setGroup] = useState([]);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    useRole(['ROOT','SUPERADMIN']);
    useTitle('Maj de l\'utilisateur');
    const fetchGroupes = () => {
        axiosInstance(accessToken).get('api/groupes').then(
            (response)=>{
                setGroup(response.data);
            }
        ).catch( (error)=>{
        });
    }

    const navigation = (link)=>{
        setLoading(true);
        nav(link);
    }
    const onUpdate = (values) => {
        setLoading(true);
        const dat =values;
        let id = 0;
        id=values.groupe;
        for (let g in groupList){
            if(groupList[g].id === id)
                dat.groupe = groupList[g];
        }
        axiosInstance(accessToken).put(
            "user/"+user.id,
            dat,
        ).then(
            (result)=> {
                message.success('User '+dat.prenom+' '+dat.nom+' Create');
                form.resetFields();
                navigation('/admin/user');
                setLoading(false);
            }).catch((result)=> {
            message.error(result.response.data.message);
            setLoading(false);
        });
    }
    const getUserById = () => {
        axiosInstance(accessToken).get('user/find-by-id/'+parseInt(params.id))
            .then(
                (response)=>{
                    setUser(response.data);
                    setLoading(false);
                }
            ).catch(
            (error)=>{
                message.error(error.response.data.message);
                setLoading(false);
            }
        )
    }

    useEffect(
        ()=>{
            setLoading(true)
            getUserById();
            fetchGroupes();
        },[]
    )
    const returnValue = user ==null ? <>
        <Spin spinning={loading}>
            <Empty />
        </Spin>
    </>  : <>
        <Spin  spinning={loading}>
            <div >
                <Row >
                    <Col span={12} offset={4}>
                        <h1 style={{alignContent: 'center'}}>Update User</h1>

                        <Form
                            onFinish={onUpdate}
                            form={form}
                            initialValues={user}
                        >
                            <Form.Item
                                label="Nom"
                                name="nom"
                                rules={
                                    [
                                        { required: true, message: 'Veuillez entrer le nom' },
                                        {type:"string", message:"Entrer un nom valide !"}
                                    ]}
                                hasFeedback>
                                <Input placeholder="entrer le nom"/>
                            </Form.Item>
                            <Form.Item
                                label="Prénom"
                                name="prenom"
                                rules={
                                    [
                                        { required: true, message: 'Veuillez entrer le nom' },
                                        {type:"string", message:"Entrer un nom valide !"}
                                    ]}
                                hasFeedback>
                                <Input placeholder="entrer le Prenom"/>
                            </Form.Item>
                            <Form.Item
                                label="Lieu de Naissance/Siege Social"
                                name="lieu"
                                rules={
                                    [
                                        { required: true, message: 'Veuillez entrer le Lieu de Naissance/Siege Social' },
                                        {type:"string", message:"Entrer une date valide !"}
                                    ]}
                                hasFeedback>
                                <Input placeholder="entrer le Lieu de Naissance/Siege Social"/>
                            </Form.Item>
                            <Form.Item
                                label="Sexe"
                                name="sexe">
                                <Select placeholder="Choisir le genre">
                                    <Select.Option value="HOMME">HOMME</Select.Option>
                                    <Select.Option value="FEMME">FEMME</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Groupe"
                                name="groupe">
                                <Select  placeholder="Choisir le groupe de l'utilisateur">
                                    {
                                        groupList.map((groupe)=>{
                                            return (<Select.Option
                                                key={groupe.id}
                                                value={groupe.id}>{groupe.name}
                                            </Select.Option>)
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="N°Tel"
                                name="numero_telephone"
                                rules={[
                                    { required: true, message: 'Veuillez entrer le n°tel' },
                                    {type:"string", message:"Entrer le numéro de téléphone!"},
                                    {len:9,message:"Veillez saisir un numéro de téléphone valide !"}
                                ]}
                                hasFeedback>
                                <Input placeholder="entrer n°Tel"/>
                            </Form.Item>
                            <Form.Item
                                label="email"
                                name="email"
                                rules={[
                                    { required: true, message: 'Veuillez entrer email' },
                                    {type:"email", message:"Entrer une adresse mail valide !"}
                                ]}
                                hasFeedback>
                                <Input placeholder="entrer l'email"/>
                            </Form.Item>
                            <Form.Item
                                label="Adresse"
                                name="location"
                                hasFeedback>
                                <Input placeholder="Saisir votre adresse" />
                            </Form.Item>
                            <Form.Item>
                                <Space style={{float:'right'}}>
                                    <Button type='primary' htmlType='submit' disabled={loading}>
                                        Submit
                                    </Button>
                                    <Button danger  disabled={loading}  onClick={() => {
                                        navigation('/admin/user')
                                    }} >
                                        <i className="fa fa-times" aria-hidden="true"></i> cancel
                                    </Button>

                                </Space>
                            </Form.Item>
                        </Form>

                    </Col>
                </Row>

            </div>
        </Spin>
    </>;


    const breadCum = [
        {
            id:0,
            last:false,
            path:'/admin/user',
            name:'Utilisateurs',
        },
        {
            id:1,
            last:true,
            path:'',
            name:params.id,
        }
    ];
    return (
        <>
            <BreadCom name={"User update"} data={breadCum}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Update
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0" style={
                                        {
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }
                                    }>
                                        {returnValue}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}