import DashBoard from "./page/dashboard/Dashboard";
import {createBrowserRouter, Navigate, Outlet, RouterProvider, useNavigate} from "react-router-dom";
import NavBar from "./components/navBar/NavBar";
import SideBar from "./components/SideBar/SideBar";
import Footer from "./components/Footer/Footer";
import Login from "./page/login/Login";
import {CustomToast} from "./components/toast/CustomToast";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import ListParametres from "./page/parametre/list/ListParametres";
import ViewParametres from "./page/parametre/view/ViewParametres";
import UpdateParametre from "./page/parametre/update/UpdateParametre";
import ListUser from "./page/user/list/ListUser";
import ViewUser from "./page/user/view/ViewUser";
import UpdateUser from "./page/user/update/UpdateUser";
import ListDenonciation from "./page/denonciation/list/ListDenonciation";
import ViewDenonciation from "./page/denonciation/view/ViewDenonciation";
import ListGroup from "./page/groupe/list/ListGroup";
import ViewGroup from "./page/groupe/view";
import ListGroupRoles from "./page/groupe/view/ListGroupRoles";
import UpdateGroup from "./page/groupe/update";
import ListGroupRolesAdd from "./page/groupe/update/ListGroupRolesAdd";
import ListArticle from "./page/article/list";
import ViewArticle from "./page/article/view/ViewArticle";
import UpdateArticle from "./page/article/update";
import ListTemoignage from "./page/temoignage/list/ListTemoignage";
import ViewTemoignage from "./page/temoignage/view";
import ListCentrePartenaire from "./page/centrepartenaire/list";
import ViewCentrePartenaire from "./page/centrepartenaire/view";
import ListMission from "./page/mission/list";
import ViewMission from "./page/mission/view";
import UpdateMission from "./page/mission/update";
import ListValeur from "./page/valeur/list";
import ViewValeur from "./page/valeur/view";
import UpdateValeur from "./page/valeur/update";
import {Result} from "antd";
import NoAccessAdminPage from "./components/permission";
import ListSondages from "./page/sondages/list/ListSondages";
import ViewSondage from "./page/sondages/view/ViewSondage";
import ListAllCourses from "./page/elearning/list/ListAllCourses";
import UpdateFormation from "./page/elearning/update/UpdateFormation";
import ListChapter from "./page/chapter/list/ListChapter";
import ViewChapter from "./page/chapter/view/ViewChapter";
import ListTheme from "./page/themes/list/ListTheme";
import ListAPropos from "./page/apropos/list/ListAPropos";
import UpdateAPropos from "./page/apropos/update/UpdateAPropos";
import ViewAPropos from "./page/apropos/view/ViewAPropos";
import Background from "./components/background/Background";
import ViewQRCode from "./page/viewQRCode/ViewQRCode";
import {LanguageContext} from "./context/LanguageContext";
import {IntlManager} from "./configurations/utils/IntlManager";
import {LoadingContext} from "./context/LoadingContext";
import SetResponseOfQuestion from "./page/sondages/update/SetResponseOfQuestion";
import ListCertificate from "./page/certificate/list/ListCertificate";
import AddCertificate from "./page/certificate/add/AddCertificate";
import UpdateCertificate from "./page/certificate/update/UpdateCertificate";
import ListSliders from "./page/sliders/list";
import ViewSlider from "./page/sliders/view";


const route = createBrowserRouter([
  {
    path: '/',
    children:[
      {
        path: '',
        element: <SignIn />
      },
      {
        path: 'login',
        element: <SignIn />
      },
      {
        path: 'admin',
        element: <Root />,
          errorElement: <ErrorPage />,
        children: [
          {
            path: 'dashboard',
            element: <DashBoard />
          },
            {
                path: 'parametre',
                errorElement: <Error />,
                children:[
                    {
                        path: '',
                        element: <ListParametres />
                    },
                    {
                        path: ':id',
                        element: <ViewParametres />
                    },
                    {
                        path: 'update/:id',
                        element: <UpdateParametre />
                    }
                ]
            },
            {
                path: 'user',
                children:[
                    {
                        path: '',
                        element: <ListUser />
                    },
                    {
                        path: ':id',
                        element: <ViewUser />
                    },
                    {
                        path: 'update/:id',
                        element: <UpdateUser />
                    }
                ]
            },
            {
                path: 'denonciation',
                children:[
                    {
                        path: '',
                        element: <ListDenonciation />
                    },
                    {
                        path: ':id',
                        element: <ViewDenonciation />
                    }
                ]
            },
            {
                path: 'qrcode',
                element: <ViewQRCode />
            },
            {
                path: 'certificate',
                children:[
                    {
                        path: '',
                        element: <ListCertificate />
                    },
                    {
                        path: 'ajouter',
                        element: <AddCertificate />
                    },
                    {
                        path: 'update/:id',
                        element: <UpdateCertificate />
                    }
                ]
            },
            {
                path: 'group',
                children:[
                    {
                        path: '',
                        element: <ListGroup />
                    },
                    {
                        path: ':id',
                        element: <ViewGroup />,
                        children:[
                            {
                                path: '',
                                element: <></>
                            },
                            {
                                path: 'role',
                                element: <ListGroupRoles />
                            }
                        ]
                    },
                    {
                        path: 'update',
                        element: <UpdateGroup />,
                        children:[
                            {
                                path: ':id',
                                errorElement: <Error />,
                                children:[
                                    {
                                        path: '',
                                        element: <></>
                                    },
                                    {
                                        path: 'role',
                                        element: <ListGroupRolesAdd />
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: 'article',
                children: [
                    {
                        path: '',
                        element: <ListArticle />
                    },
                    {
                        path: ':id',
                        element: <ViewArticle />
                    },
                    {
                        path: 'update/:id',
                        element: <UpdateArticle />
                    }
                ]
            },
            {
                path: 'apropos',
                children: [
                    {
                        path: '',
                        element: <ListAPropos />
                    },
                    {
                        path: ':id',
                        element: <ViewAPropos />
                    },
                    {
                        path: 'update/:id',
                        element: <UpdateAPropos />
                    }
                ]
            },
            {
                path: 'temoignage',
                children: [
                    {
                        path: '',
                        element: <ListTemoignage />
                    },
                    {
                        path: ':id',
                        element: <ViewTemoignage />
                    }
                ]
            },
            {
                path: 'centrepartenaire',
                children: [
                    {
                        path: '',
                        element: <ListCentrePartenaire />
                    },
                    {
                        path: ':id',
                        element: <ViewCentrePartenaire />
                    }
                ]
            },
            {
                path: 'sliders',
                children: [
                    {
                        path: '',
                        element: <ListSliders />
                    },
                    {
                        path: ':id',
                        element: <ViewSlider />
                    }
                ]
            },
            {
                path: 'theme',
                children: [
                    {
                        path: '',
                        element: <ListTheme />
                    }/*,
                    {
                        path: ':id',
                        element: <ViewMission />
                    },
                    {
                        path: 'update/:id',
                        element: <UpdateMission />
                    }*/
                ]
            },
            {
                path: 'mission',
                children: [
                    {
                        path: '',
                        element: <ListMission />
                    },
                    {
                        path: ':id',
                        element: <ViewMission />
                    },
                    {
                        path: 'update/:id',
                        element: <UpdateMission />
                    }
                ]
            },
            {
                path: 'valeur',
                children: [
                    {
                        path: '',
                        element: <ListValeur />
                    },
                    {
                        path: ':id',
                        element: <ViewValeur />
                    },
                    {
                        path: 'update/:id',
                        element: <UpdateValeur />
                    }
                ]
            },
            {
                path: 'quizz',
                children: [
                    {
                        path: '',
                        element: <ListSondages />
                    },
                    {
                        path: ':id',
                        element: <ViewSondage />
                    },
                    {
                        path: 'update/:id',
                        element: <SetResponseOfQuestion />
                    },
                    /*{
                        path: 'update/:id',
                        element: <UpdateValeur />
                    }*/
                ]
            },
            {
                path: 'formation',
                children: [
                    {
                        path: '',
                        element: <ListAllCourses />
                    },
                    {
                        path: ':id',
                        element: <ViewSondage />
                    },
                    {
                        path: 'update',
                        element: <UpdateFormation />,
                        children:[
                            {
                                path: ':id',
                                errorElement: <>< />,
                                children:[
                                    {
                                        path: '',
                                        element: <></>
                                    },
                                    {
                                        path: 'view/:idChapter',
                                        element: <ViewChapter />
                                    },
                                    {
                                        path: 'chatpter',
                                        element: <ListChapter />
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: 'unauthorize',
                element: <NoAccessAdminPage />
            },
            {
                path: '*',
                element: <Error />
            }
        ]

      },
      {
          path: '*',
          element: <Navigate to="/" replace />
      }
    ]
  }
]);

function Root() {
    const {user} = useSelector(state => state.authUser);
    const [langue, setLangue] = useState(navigator.language.split('-')[0] || 'en');
    const [loading, setLoading] = useState(true);
    const valueLanguage = {
        langue: langue,
        setLangue: setLangue
    }
    const valueLoading = {
        loading: loading,
        setLoading: setLoading
    }
    const nav = useNavigate();
    useEffect(
        ()=>{
            if(user==null){
                nav('/');
            }

        },[]
    );
  return (
      <LoadingContext.Provider value={valueLoading}>
          <LanguageContext.Provider value={valueLanguage}>
              <IntlManager>
                  {/*<InactivityHandler />*/}
                  <div className="wrapper">
                      {(user===null) ? <></> : <>
                          <Background />
                          <NavBar />
                          <SideBar />
                          <div className="content-wrapper">
                              <CustomToast />
                              <Outlet />
                          </div>
                          <Footer />
                      </> }
                  </div>
              </IntlManager>
          </LanguageContext.Provider>
      </LoadingContext.Provider>
  );

}

function SignIn() {
  return (
      <>
          <Background />
          <CustomToast />
          <Login />
      </>
  );

}

function Error() {
    return (
        <div className="content-wrapper">
            <Result
                status="404"
                title="404"
                subTitle="Page not found"
            />
        </div>
    );
}
function ErrorPage() {
    const {user} = useSelector(state => state.authUser);
    const [langue, setLangue] = useState(navigator.language.split('-')[0] || 'en');
    const [loading, setLoading] = useState(true);
    const valueLanguage = {
        langue: langue,
        setLangue: setLangue
    }
    const valueLoading = {
        loading: loading,
        setLoading: setLoading
    }
    const nav = useNavigate();
    useEffect(
        ()=>{
            if(user==null){
                nav('/');
            }

        },[]
    );
    return (
        <LoadingContext.Provider value={valueLoading}>
            <LanguageContext.Provider value={valueLanguage}>
                <IntlManager>
                    {/*<InactivityHandler />*/}
                    <div className="wrapper">
                        {(user===null) ? <></> : <>
                            <Background />
                            <NavBar />
                            <SideBar />
                            <div className="content-wrapper">
                                <CustomToast />
                                <Error />
                            </div>
                            <Footer />
                        </> }
                    </div>
                </IntlManager>
            </LanguageContext.Provider>
        </LoadingContext.Provider>
    );
}
function App() {
  return <RouterProvider router={route} />;
}
export default App;
