import BreadCom from "../../components/breadCom/BreadCom";
import {FileTextOutlined, GlobalOutlined, HomeOutlined, UsergroupAddOutlined, UserOutlined} from "@ant-design/icons";
import {ARTICLE, DENONCIATION, USER} from "../../components/utils/Constantes";
import {useSelector} from "react-redux";
import {useRole, useTitle} from "../../components/hook";
import {useEffect, useState} from "react";
import "./layout.css";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import InscriptionStory from "./byDate/InscriptionStory";
import {Button, Select} from "antd";
import PercentMenWomen from "./bySexe/PercentMenWomen";

export default function DashBoard(){
    const {user, accessToken, droits} = useSelector(state => state.authUser);
    const[loading, setLoading] = useState(true);
    const[data, setData] = useState({});
    const[dataByDate, setDataByDate] = useState([]);
    const[dataToView, setDataToView] = useState(null);
    const[options, setOptions] = useState([]);
    const [dataMvsW, setDataMvsW] = useState([]);
    useRole(['ROOT','SUPERADMIN']);
    useTitle('Dashboard');


    const selectYear = (e)=>{
        dataByDate.forEach(value => {
            if(value.year==e){
                setDataToView(value.data)
            }
        });
    }
    const getStatistique = async ()=>{
        setLoading(true);
        await axiosInstance(accessToken).get(
            "user/dashboard"
        ).then(
            (response)=>{
                let user = response.data.user;
                let dataResponse = response.data;
                if(typeof response.data.user === "number"){
                    dataResponse.user = parseInt(user);
                }else {
                    dataResponse.user = 0;
                }
                setData(dataResponse)
                let byDates = response.data.byDate;
                let option = [];
                for (const byDatesKey in byDates) {
                    option.push({
                        value: byDates[byDatesKey].year,
                        label: byDates[byDatesKey].year
                    });
                }
                setOptions(option);
                setDataByDate(byDates);
                let datas = [];
                datas.push({
                    name: "Men",
                    count: dataResponse.sexe.men,
                    fill: "blue",
                });
                datas.push({
                    name: "Women",
                    count: dataResponse.sexe.women,
                    fill: "pink",
                });
                datas.push({
                    name: "Other",
                    count: dataResponse.sexe.other,
                    fill: "yellow",
                });
                if((dataResponse.sexe.other+dataResponse.sexe.women+dataResponse.sexe.men)<dataResponse.sexe.all){
                    datas.push({
                        name: "Error",
                        count: dataResponse.sexe.all-(dataResponse.sexe.other+dataResponse.sexe.women+dataResponse.sexe.men),
                        fill: "black",
                    });
                }
                setDataMvsW(datas)
                setLoading(false);
            }
        ).catch(
            (error)=>{
                setLoading(false);
            }
        );
    }
    useEffect(() => {
        getStatistique();
    }, []);
    return(
        <>
            <BreadCom name={"DashBoard"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        {
                            data.user!=null &&  <div className="col-lg-3 col-6">
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{data.user}</h3>
                                        <p>Utilisateurs</p>
                                    </div>
                                    <div className="icon">
                                        <i className=""><UsergroupAddOutlined /></i>
                                    </div>
                                    <a href={USER} className="small-box-footer">Plus d'information</a>
                                </div>
                            </div>
                        }
                        {
                            data.cp!=null &&  <div className="col-lg-3 col-6">
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{data.cp}</h3>
                                        <p>Centres Partenaires</p>
                                    </div>
                                    <div className="icon">
                                        <i className=""><HomeOutlined /></i>
                                    </div>
                                    <a href="#" className="small-box-footer">Plus d'information</a>
                                </div>
                            </div>
                        }
                        {
                            data.article!=null &&  <div className="col-lg-3 col-6">
                                <div className="small-box bg-warning">
                                    <div className="inner">
                                        <h3>{data.article}</h3>

                                        <p>Articles</p>
                                    </div>
                                    <div className="icon">
                                        <i className=""><FileTextOutlined /></i>
                                    </div>
                                    <a href={ARTICLE} className="small-box-footer">Plus d'information</a>
                                </div>
                            </div>
                        }
                        {
                            data.denonciation != null &&  <div className="col-lg-3 col-6">
                                <div className="small-box bg-danger">
                                    <div className="inner">
                                        <h3>{data.denonciation}</h3>

                                        <p>Denonciations</p>
                                    </div>
                                    <div className="icon">
                                        <i className=""><GlobalOutlined /></i>
                                    </div>
                                    <a href={DENONCIATION} className="small-box-footer">Plus d'information</a>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row">
                        <section className="col-lg-12 connectedSortable">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>

                                    </h3>
                                    <div className="card-tools">
                                        <ul className="nav nav-pills ml-auto">
                                            <li className="nav-item">
                                                <a className="nav-link active" href="#revenue-chart"
                                                   data-toggle="tab">Statistiques creation de compte</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#sales-chart"
                                                   data-toggle="tab">Pourcentage d'utilisateur</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <div className="chart tab-pane active" id="revenue-chart"
                                             style={{position: "relative",height:"300px"}}>
                                            <Select placeholder="Choisir une annee" onChange={selectYear} options={options} />
                                            {
                                                dataToView != null && <InscriptionStory data={dataToView} />
                                            }
                                        </div>
                                        <div className="chart tab-pane" id="sales-chart"
                                             style={{position:"relative", height:"300px"}}>
                                            {/*<PercentMenWomen data={dataMvsW}/>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}