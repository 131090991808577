import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Button, Empty, Form, Input, message, Modal, Select, Space, Spin} from "antd";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {Editor} from "react-draft-wysiwyg";
import {convertFromRaw, EditorState} from "draft-js";
import {useParams} from "react-router-dom";
import {ELEARNING} from "../../../components/utils/Constantes";
import {useRole} from "../../../components/hook";
import BreadCom from "../../../components/breadCom/BreadCom";

export default function ViewChapter() {
    const {accessToken} = useSelector(state => state.authUser);
    const params = useParams();
    const [chapter, setChapter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [editorStates, setEditorState] = useState([]);
    useRole(['ROOT','ADMIN','SUPERADMIN']);

    const breadCum = [
        {
            id:0,
            last:false,
            path:'/admin/formation',
            name:'Formation',
        },
        {
            id:1,
            last:true,
            path:params.id,
            name:params.id,
        }
    ];

    const openModal =()=>{
        setLoading(true);
        getById();
    }


    const getById = () => {
        axiosInstance(accessToken).get('test/api/chapter/admin/'+parseInt(params.idChapter))
            .then(
                (response)=>{
                    let chapitre = response.data;
                    let paragraph = [];
                    paragraph=response.data.paragraphes;
                    paragraph.sort(function(a, b){return a.id - b.id});
                    chapitre.paragraphes=paragraph;
                    setChapter(chapitre);
                    setLoading(false);
                }
            ).catch(
            (error)=>{
                message.error(error.response.data.message);
                setLoading(false);
            }
        )
    }
    useEffect(
        ()=>{
            openModal();
        },[]
    )

    const returnValue = chapter ==null ? <>
        <Spin spinning={loading}>
            <Empty />
        </Spin>
    </>  : <>
        <Spin  spinning={loading}>
            <div style={{background: '#ffffff00'}}>
                <label>
                    <a href={ELEARNING+'/update/'+params.id+"/chatpter"}>Retour</a>
                </label>
                <hr />
                <label>
                    <strong>Titre :</strong> <span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{chapter.title}</span>
                </label>
                <hr />
                <div style={{padding: "5px", marginTop: '5px', width: '100%'}}>

                    {
                        chapter.paragraphes.map((value) =>
                            <div key={value.id}  >
                                {
                                    value.type === "TEXT" &&
                                    <Editor
                                        toolbarClassName="toolbar-class1"
                                        readOnly={true}
                                        editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(value.content)))}
                                        toolbar={{options: []}}
                                    />
                                }
                                {
                                    value.type === "IMAGE" && <div style={{ textAlign: 'center' }}>
                                        <img src={value.content} style={{ width: '95%', maxWidth: '600px', height: 'auto' }}/>
                                        <p><small>{value.description}</small></p>
                                    </div>

                                    }
                            </div>)
                    }
                </div>
            </div>
        </Spin>
    </>
    return(
        <>
            <BreadCom name={"Formations"} data={breadCum} />
            <Spin spinning={loading}>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <section className="col-lg-12">
                                <div className="card card-primary" style={{background: '#ffffff00'}}>
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            <i className="fas fa-chart-pie mr-1"></i>
                                            Detail
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content p-0">
                                            {returnValue}
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </section>
            </Spin>
        </>
    );
}