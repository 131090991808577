import BreadCom from "../../../components/breadCom/BreadCom";
import {
    EditFilled,
    InfoCircleFilled} from "@ant-design/icons";
import {constantes} from "../../../components/utils/Constantes";
import {useSelector} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {Button, Space, Tag, Tooltip} from "antd";
import {Active} from "../../../components/active";
import AddParametre from "../add/AddParametre";
import {LoadingContext} from "../../../context/LoadingContext";
import LoadingPage from "../../../components/loadingPage/LoadingPage";
import ViewTable from "../../../components/table/viewTable";

export default function ListParametres(){
    const {accessToken} = useSelector(state => state.authUser);
    const {loading, setLoading} = useContext(LoadingContext)
    const [pageable, setPageable] = useState(constantes);
    const [url, setUrl] = useState("api/parametres?page=");
    const [data, setData] = useState([]);
    const [current, setCurrent] = useState(0);
    const nav = useNavigate();
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('Liste des Parametres');
    const navigate= (link)=>{
        nav(link);
    }
    const breadCum = [
        {
            id:0,
            last:true,
            path:'Parametres',
            name:'Parametre',
        }
    ];
    const columns = [
        {
            accessor: "info",
        },
        {
            accessor: "Title",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "Valeur",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "Taille",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "Type",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "Status",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "action",
        },
    ];
    const renderRow = (item) => {
        const classHover = "border-b border-gray-200 even:bg-slate-50 text-sm hover:bg-lamaYellow";
        return (
            <tr
                key={item.id}
                className={classHover}
            >
                <td className="flex items-center gap-4 p-4">
                    <div className="flex flex-col">
                        <h3 className="font-semibold">{item.id}</h3>
                        {/*<p className="text-xs text-gray-500">{item?.email}</p>*/}
                    </div>
                </td>
                <td className="hidden md:table-cell">{item.titre}</td>
                <td className="hidden md:table-cell">{item.valeur}</td>
                <td className="hidden md:table-cell">{item.taille}</td>
                <td className="hidden md:table-cell">{item.paramTypeEnum}</td>
                <td>
                    <div className="flex items-center gap-2">
                        {item.status ? (<>
                            <Tag color="green">
                                Actif
                            </Tag>
                        </>) : (
                            <>
                                <Tag color="volcano">
                                    Inactif
                                </Tag>
                            </>
                        )}
                    </div>
                </td>
                <td>
                    <div className="flex items-center gap-2">
                        <Space>
                            <Tooltip title={"Detail sur le Parametre"}>
                                <Button type="default" shape="circle"  onClick={() => navigate('/admin/parametre/'+item.id)} >
                                    <i aria-hidden="true"><InfoCircleFilled /></i>
                                </Button>
                            </Tooltip>
                            <Tooltip title={"Mise a jour d'un Parametre"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/parametre/update/'+item.id)} >
                                    <i aria-hidden="true"><EditFilled /></i>
                                </Button>
                            </Tooltip>
                            <Active status={item.status} path={"api/parametres/active/"} id={item.id}/>
                        </Space>
                    </div>
                </td>
            </tr>
        );
    }
    const fetchTemoignagesByCategory = (categorie, color, page=0)=>{//
        setLoading(true);
        let localUrl = 'api/parametres/'+categorie+'?page=';
        setUrl(localUrl);
        axiosInstance(accessToken).get(localUrl+page).then(
            (result)=>{
                setCurrent(color);
                setData(result.data.content);
                setPageable({
                    pageable: result.data.pageable,
                    last: result.data.last,
                    totalElements: result.data.totalElements,
                    totalPages: result.data.totalPages,
                    size: result.data.size,
                    number: result.data.number,
                    first: result.data.first,
                    numberOfElements: result.data.numberOfElements,
                    empty: result.data.empty
                });
                setLoading(false);
            }).catch(
            (error)=>{
                setData([]);
                setPageable(constantes);
                setLoading(false);
        });
    }
    const fetchArticle = (page=0)=>{
        setLoading(true);
        let localUrl="api/parametres?page=";
        setUrl(localUrl)
        setCurrent(0);
        axiosInstance(accessToken).get(
            localUrl+page
        ).then((result)=> {
            setData(result.data.content);
            setPageable({
                pageable: result.data.pageable,
                last: result.data.last,
                totalElements: result.data.totalElements,
                totalPages: result.data.totalPages,
                size: result.data.size,
                number: result.data.number,
                first: result.data.first,
                numberOfElements: result.data.numberOfElements,
                empty: result.data.empty
            });
            setLoading(false);
        }).catch(
            (error)=>{
                setData([]);
                setPageable(constantes);
                setLoading(false);
        });
    }
    useEffect(()=>{
        fetchArticle()
    },[]);
    return(
        <>
            <BreadCom name={"Liste des Parametres"} data={breadCum}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Liste des Parametres
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        {
                                            loading ? <LoadingPage /> : <>
                                                <div style={{margin: '15px'}}>
                                                    <Tag
                                                        title={"Tous les Parametres"}
                                                        style={{cursor: 'pointer'}}
                                                        color={current === 0 ? "green" : "blue"}
                                                        key={'all'}
                                                        onClick={()=>fetchArticle(0)}>
                                                        Tous
                                                    </Tag>
                                                    <Tag
                                                        title={"Numero WhatsApp"}
                                                        style={{cursor: 'pointer'}}
                                                        color={current === 1 ? "green" : "blue"}
                                                        key={'wa'}
                                                        onClick={()=>fetchTemoignagesByCategory('WHATSAPP',1, 0)}>
                                                        WhatsApp
                                                    </Tag>
                                                    <Tag
                                                        title={"Numero Appel"}
                                                        style={{cursor: 'pointer'}}
                                                        color={current === 2 ? "green" : "blue"}
                                                        key={'call'}
                                                        onClick={()=>fetchTemoignagesByCategory('CALL',2, 0)}>
                                                        Appel Telephonique
                                                    </Tag>
                                                    <Tag
                                                        title={"Numero Message"}
                                                        style={{cursor: 'pointer'}}
                                                        color={current === 3 ? "green" : "blue"}
                                                        key={'sms'}
                                                        onClick={()=>fetchTemoignagesByCategory('SMS',3, 0)}>
                                                        Messagerie Telephonique
                                                    </Tag>
                                                    <Tag
                                                        title={"Statistiques Medicales"}
                                                        style={{cursor: 'pointer'}}
                                                        color={current === 4 ? "green" : "blue"}
                                                        key={'stat'}
                                                        onClick={()=>fetchTemoignagesByCategory('STATISTICS',4, 0)}>
                                                        Statistiques Medicales
                                                    </Tag>
                                                    <Tag
                                                        title={"Lien FaceBook"}
                                                        style={{cursor: 'pointer'}}
                                                        color={current === 5 ? "green" : "blue"}
                                                        key={'fbk'}
                                                        onClick={()=>fetchTemoignagesByCategory('FACEBOOK',5, 0)}>
                                                        Lien Facebook
                                                    </Tag>
                                                    <Tag
                                                        title={"Lien Tweeter"}
                                                        style={{cursor: 'pointer'}}
                                                        color={current === 6 ? "green" : "blue"}
                                                        key={'tweeter'}
                                                        onClick={()=>fetchTemoignagesByCategory('TWEETER',6, 0)}>
                                                        Lien Tweeter
                                                    </Tag>
                                                    <Tag
                                                        title={"Lien Youtube"}
                                                        style={{cursor: 'pointer'}}
                                                        color={current === 7 ? "green" : "blue"}
                                                        key={'ytb'}
                                                        onClick={()=>fetchTemoignagesByCategory('YOUTUBE',7, 0)}>
                                                        Lien Youtube
                                                    </Tag>
                                                    <Tag
                                                        title={"Statistiques Carte"}
                                                        style={{cursor: 'pointer'}}
                                                        color={current === 8 ? "green" : "blue"}
                                                        key={'carte'}
                                                        onClick={()=>fetchTemoignagesByCategory('LOCALISATION',8, 0)}>
                                                        Statistiques Carte
                                                    </Tag>
                                                </div>
                                                <ViewTable data={data} pageable={pageable} setData={setData} url={url} setPageable={setPageable} renderRow={renderRow} columns={columns} addBTN={<AddParametre loading={"right"} action={"Ajouter"} />} />
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}