import {useSelector} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {Button, Space, Tag, Tooltip} from "antd";
import {EditOutlined, InfoCircleFilled} from "@ant-design/icons";
import BreadCom from "../../../components/breadCom/BreadCom";
import AddSondage from "../add/AddSondage";
import {Archive} from "../../../components/archive";
import {LoadingContext} from "../../../context/LoadingContext";
import {constantes} from "../../../components/utils/Constantes";
import ViewTable from "../../../components/table/viewTable";
import LoadingPage from "../../../components/loadingPage/LoadingPage";
import SetResponseOfQuestion from "../update/SetResponseOfQuestion";

export default function ListSondages(){
    const {accessToken, droits} = useSelector(state => state.authUser);
    const {loading, setLoading} = useContext(LoadingContext)
    const [pageable, setPageable] = useState(constantes);
    const [url, setUrl] = useState("test/api/sondage?page=");
    const [data, setData] = useState([]);
    const nav = useNavigate();
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('Liste des Sondages');
    const navigate= (link)=>{
        nav(link);
    }

    const fetchSondages = (page = 0)=>{
        setLoading(true);
        axiosInstance(accessToken).get(url+page).then(
            (result)=>{
                setData(result.data.content);
                setPageable({
                    pageable: result.data.pageable,
                    last: result.data.last,
                    totalElements: result.data.totalElements,
                    totalPages: result.data.totalPages,
                    size: result.data.size,
                    number: result.data.number,
                    first: result.data.first,
                    numberOfElements: result.data.numberOfElements,
                    empty: result.data.empty
                });
                setLoading(false);
            }).catch(
            (error)=>{
                setData([]);
                setPageable(constantes);
                setLoading(false);
            }
        );
    }
    useEffect(() => {
        fetchSondages();
    }, []);


    const breadCum = [
        {
            id:0,
            last:true,
            path:'Quizz',
            name:'Quizz',
        }
    ];
    const columns = [
        {
            accessor: "info",
        },
        {
            accessor: "Name",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "Cible",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "Nombre de Questions",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "status",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "action",
        },
    ];

    const viewEditBTN = (item)=>{
        const options = [];
        item.questions.map((value, index) => {
            if(value.score===0){
                options.push({
                    value: value.id,
                    label: value.libelle,
                })
            }else {
                let canPush = true;
                value.modelResponses.map(value1 => {
                    if(value.score===value1.score) {
                        canPush = false;
                    }
                }) ;
                if(canPush){
                    options.push({
                        value: value.id,
                        label: value.libelle,
                    })
                }
            }
        });
        if(options.length === 0){
            return false;
        }else {
            return true;
        }
    }

    const renderRow = (item) => {
        const classHover = "border-b border-gray-200 even:bg-slate-50 text-sm hover:bg-lamaYellow";
        const data = ()=>{
            if(item.state === 'CREATED'){
                return <>
                    <Tooltip title={"Detail sur le sondage"}>
                        <Button type="primary" shape="circle"  onClick={() => navigate('/admin/quizz/'+item.id)} >
                            <i aria-hidden="true"><InfoCircleFilled /></i>
                        </Button>
                    </Tooltip>
                    <Archive key={item.id+"active"} path={"test/api/sondage/update/"+item.id+"/ACTIVE"} text={"ACTIVE"}/>
                    {/*<Schedule key={item.id+"schedule"} path={"test/api/sondage/update/"+item.id} text={"Schedule"}/>*/}
                </>;
            }else if (item.state === 'SCHEDULED'){
                return <>
                    <Tooltip title={"Detail sur le sondage"}>
                        <Button type="primary" shape="circle"  onClick={() => navigate('/admin/quizz/'+item.id)} >
                            <i aria-hidden="true"><InfoCircleFilled /></i>
                        </Button>
                    </Tooltip>
                    <Archive key={item.id+"active"} path={"test/api/sondage/update/"+item.id+"/ACTIVE"} text={"ACTIVE"}/>
                    <Archive key={item.id+"Archive"} path={"test/api/sondage/update/"+item.id+"/ARCHIVE"} text={"ARCHIVE"}/>
                </>;
            }else if (item.state === 'ACTIVE'){
                return <>
                    <Tooltip title={"Detail sur le sondage"}>
                        <Button type="primary" shape="circle"  onClick={() => navigate('/admin/quizz/'+item.id)} >
                            <i aria-hidden="true"><InfoCircleFilled /></i>
                        </Button>
                    </Tooltip>
                    <Archive key={item.id+"Archive"} path={"test/api/sondage/update/"+item.id+"/ARCHIVE"} text={"ARCHIVE"}/>
                </>;
            }else{
                return <></>;
            }
        };
        return (
            <tr
                key={item.id}
                className={classHover}
            >
                <td className="flex items-center gap-4 p-4">
                    <div className="flex flex-col">
                        <h3 className="font-semibold">{item.id}</h3>
                        {/*<p className="text-xs text-gray-500">{item?.email}</p>*/}
                    </div>
                </td>
                <td className="hidden md:table-cell">{item.name}</td>
                <td className="hidden md:table-cell">{item.typeUser}</td>
                <td className="hidden md:table-cell">{item.questions.length}</td>
                <td>
                    <div className="flex items-center gap-2">
                        <Tag color="volcano">
                            {item.state}
                        </Tag>
                    </div>
                </td>
                <td>
                    <div className="flex items-center gap-2">
                        <Space>
                            {data()}{item.typeUser =="FORMATION" && viewEditBTN(item) && <Button style={{float: "right"}}  onClick={()=>{nav("/admin/quizz/update/"+item.id)}} >
                            <i aria-hidden="true"><EditOutlined /></i>Choisir les bonnes reponses aux questions
                        </Button>}
                        </Space>
                    </div>
                </td>
            </tr>
        );
    }

    return(
        <>
            <BreadCom name={"Liste des Sondages"} data={breadCum}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Liste des Sondages
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        {
                                            loading ? <LoadingPage /> : <ViewTable data={data} pageable={pageable} setData={setData} url={url} setPageable={setPageable} renderRow={renderRow} columns={columns} addBTN={<AddSondage />} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );

}