import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {toastClick} from "../../../components/toast/CustomToast";
import {useSelector} from "react-redux";
import {Col, Empty, Row, Spin, Tag} from "antd";
import BreadCom from "../../../components/breadCom/BreadCom";

export default function ViewSondage(){
    const {accessToken} = useSelector(state => state.authUser);
    const params = useParams();
    const nav = useNavigate();
    const [sondage, setSondage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [ddn, setDdn] = useState('');
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('View Sondage');
    const breadCum = [
        {
            id:0,
            last:false,
            path:'/admin/quizz',
            name:'Quizz',
        },
        {
            id:1,
            last:true,
            path:params.id,
            name:params.id,
        }
    ];
    const navigation = (link)=>{
        setLoading(true);
        nav(link);
    }
    const getSondageById = () => {
        axiosInstance(accessToken).get('test/api/sondage/get/'+parseInt(params.id))
            .then(
                (response)=>{
                    setSondage(response.data);
                    if(response.data.createDate != null){
                        let date = new Date(response.data.createDate);
                        setDdn((date.getDay()+1)+'-'+(date.getMonth()+1)+'-'+date.getFullYear());
                    }
                    setLoading(false);
                }
            ).catch(
            (error)=>{
                toastClick(error.response.data.message,'error');
                setLoading(false);
            }
        )
    }

    useEffect(
        ()=>{
            setLoading(true)
            getSondageById();
        },[]
    );
    const returnValue = sondage ==null ? <>
        <Spin spinning={loading}>
            <Empty />
        </Spin>
    </>  : <>
        <Spin  spinning={loading}>
            <div>

            </div>
            <div>
                        <label>
                            <strong>Nom :</strong> <span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{sondage.name}</span>
                        </label>
                        <hr/>
                        <label>
                            <strong>Createur :</strong> <span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{sondage.createUser}</span>
                        </label>
                        <hr/>
                        <label>
                            <strong>Date de Creation :</strong><span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{sondage.createDate == null ? "null" : ddn}</span>
                        </label>
                        <hr/>
                        <label>
                            <strong>Score :</strong><span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{sondage.scoreTotal}%</span>
                        </label>
                        <hr/>
                            {
                                sondage.questions==null ?
                                    <></>
                                    :
                                    <section className="content">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <section className="col-lg-12">
                                                    <div className="card card-primary">
                                                        <div className="card-header">
                                                            <h3 className="card-title">
                                                                <i className="fas fa-chart-pie mr-1"></i>
                                                                Questions
                                                            </h3>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="tab-content p-0">
                                                                {
                                                                    sondage.questions == null
                                                                        ?
                                                                        <></>
                                                                        :
                                                                        <>
                                                                            {
                                                                                sondage.questions.map((value, index)=>{
                                                                                    return <>
                                                                                        <h4>Questions {index+1}</h4>
                                                                                        <label>
                                                                                            <strong>Libelle :</strong> <span style={{padding: "5px", marginTop: '5px'}}>{value.libelle}</span>
                                                                                        </label>
                                                                                        <br/>
                                                                                        <label>
                                                                                            <strong>Type :</strong> <span style={{padding: "5px", marginTop: '5px'}}>{value.libelle}</span>
                                                                                        </label>
                                                                                        <br/>
                                                                                        <label>
                                                                                            <strong>Score :</strong> <span style={{padding: "5px", marginTop: '5px'}}>{value.score} Points</span>
                                                                                        </label>
                                                                                        <br/>
                                                                                        <label>
                                                                                            <strong>Reponses :</strong><br />
                                                                                            {
                                                                                                value.modelResponses==null ? <></>:
                                                                                                    <>
                                                                                                        {
                                                                                                            value.modelResponses.map(modelResponses=>{
                                                                                                                return <div>
                                                                                                                    <span style={{padding: "5px", marginTop: '5px'}}>{modelResponses.value+' ('+modelResponses.score+' Points)'}</span>
                                                                                                                </div>

                                                                                                            })
                                                                                                        }
                                                                                                    </>
                                                                                            }
                                                                                        </label>
                                                                                        <hr />
                                                                                    </>
                                                                                })
                                                                            }
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>

                                            </div>
                                        </div>
                                    </section>
                            }
            </div>
        </Spin>
    </>;

    return(
        <>
            <BreadCom name={"Sondage info"} data={breadCum}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Detail
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        {returnValue}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );


}