import './cssFile.css';
export default function LoadingPage(){

    return  <div
        style={{ minHeight: '450.4px' }}
        className="w-full h-full flex flex-col items-center justify-center transition-all duration-500"
    >
        <div className="body-element">
            <span className="element">↓</span>
            <span className="element" style={{ "--delay": "0.1s" }}>↓</span>
            <span className="element" style={{ "--delay": "0.3s" }}>↓</span>
            <span className="element" style={{ "--delay": "0.4s" }}>↓</span>
            <span className="element" style={{ "--delay": "0.5s" }}>↓</span>
        </div>
        <div className="mt-4">
            <img src="/logo512.png" alt="Logo" width={'50px'} height={'50px'}
                 className="brand-image img-circle" style={{opacity:".8"}} />
        </div>
        <div className="body-element mt-4">
            <span className="element">↑</span>
            <span className="element" style={{ "--delay": "0.1s" }}>↑</span>
            <span className="element" style={{ "--delay": "0.3s" }}>↑</span>
            <span className="element" style={{ "--delay": "0.4s" }}>↑</span>
            <span className="element" style={{ "--delay": "0.5s" }}>↑</span>
        </div>
    </div>
}