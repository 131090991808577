import {useSelector} from "react-redux";
import React, {useState} from "react";
import {Button, Form, Input, message, Modal, Select, Space, Spin, Tooltip, Upload} from "antd";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {Editor} from "react-draft-wysiwyg";
import FontSizePicker from "../../article/toolbar/FontSize";
import FontFamilyPicker from "../../article/toolbar/FontFamilyPicker";
import {convertToRaw, EditorState, RichUtils} from "draft-js";
import {ELEARNING} from "../../../components/utils/Constantes";
import {InfoCircleFilled, UploadOutlined} from "@ant-design/icons";
import {useRole} from "../../../components/hook";

export default function AddParagraph({chapter}) {
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    const {accessToken} = useSelector(state => state.authUser);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [paragraphType, setParagraphType] = useState('');
    const [description, setDescription] = useState('');
    const [addBTN, setAddBTN] = useState(true);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty()
    );

    const handleFontSizeChange = (fontSize) => {
        const newEditorState = RichUtils.toggleInlineStyle(editorState, `FONT_SIZE_${fontSize}`);
        setEditorState(newEditorState);
    };

    const handleOnChangeDescription = (event)=>{
        if(event.target.value.trim().length>4){
            setDescription(event.target.value.trim())
            if(file==null) {
                setAddBTN(true);
            }else {
                setAddBTN(false);
            }
        }else {
            setAddBTN(true);
        }

    }
    const handleFontChange = (selectedFont) => {
        const newEditorState = RichUtils.toggleInlineStyle(editorState, `FONT_${selectedFont}`);
        setEditorState(newEditorState);
    };

    const selectParagraphType = (event)=>{
        try{
            setParagraphType(event);
            setEditorState(EditorState.createEmpty());
            setFile(null);
            setDescription('');
            document.getElementById('description').value='';
        }catch (e) {

        }
    }



    const openModal =()=>{
        form.resetFields();
        setLoading(false)
        setModalAddOpen(true)
        setEditorState(EditorState.createEmpty());
    }
    const onSubmit = (values) => {
        setLoading(true);
        let data = paragraphType==='TEXT' ? {
            id: null,
            type: paragraphType,
            content: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
            status: true,
            description:null
        } : {
            id: null,
            type: paragraphType,
            content: null,
            status: true,
            description:description
        };
        const formData = new FormData();
        formData.append('file', file);
        formData.append('jsonData', JSON.stringify(data));
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        axiosInstance(accessToken).post(
            "test/api/paragraph/"+chapter.id,
            formData,config
        ).then(
            (result)=> {
                message.success('Paragraphe Create');
                form.resetFields();
                setEditorState(EditorState.createEmpty());
                setLoading(false);
                setTimeout(
                    ()=>{
                        window.location.reload();
                        setModalAddOpen(false);
                    },2000
                )
            }).catch((result)=> {
            message.error(result.response.data.message);
            setLoading(false);
        });
    };

    const getFiles = (event)=>{
        if(event.target.files.length>0) {
            setFile(event.target.files[0]);
            if(description.trim().length<=4) {
                setAddBTN(true);
            }else {
                setAddBTN(false);
            }
        }else {
            setAddBTN(true);
        }
    }

    const uploadCallback = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve({ data: { link: e.target.result } }); // URL de l'image
            };
            reader.onerror = (e) => reject(e);
            reader.readAsDataURL(file);
        });
    };
    return(
        <>
            <Tooltip title={"Ajouter un Paragraphe"}>
                <Button shape="circle" style={{float: "right"}}  onClick={openModal} >
                    <i aria-hidden="true"><InfoCircleFilled /></i>
                </Button>
            </Tooltip>

            <Modal
                title="Creation d'un Chapitre"
                style={{
                    top: 20,
                }}
                width={1000}
                open={modalAddOpen}
                maskClosable={false}
                closable={false}
                footer={[]}
            >
                <Spin spinning={loading}>
                    <Form onFinish={onSubmit} form={form}>

                        <>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <section className="col-lg-12">
                                            <div className="card card-primary">
                                                <div className="card-header">
                                                    <h3 className="card-title">
                                                        <i className="fas fa-chart-pie mr-1"></i>
                                                        Chapitre: <small>{chapter.title}</small>
                                                    </h3>
                                                </div>
                                                <div className="card-body">
                                                    <Select placeholder="Choisir le type de paragraphe a ajouter" onSelect={selectParagraphType}>
                                                        <Select.Option value="IMAGE">IMAGE</Select.Option>
                                                        <Select.Option value="TEXT">TEXT</Select.Option>
                                                    </Select>
                                                    <hr />
                                                    <div className="tab-content p-0">
                                                        {
                                                            paragraphType !=='' && <>
                                                                {
                                                                    paragraphType==='TEXT' && <>
                                                                        {/*<Editor
                                                                            editorState={editorState}
                                                                            onEditorStateChange={setEditorState}
                                                                            toolbar={
                                                                                    {options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'remove', 'history'], inline: {
                                                                                        inDropdown: false, className: undefined, component: undefined, dropdownClassName: undefined, options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],}, blockType: {
                                                                                        inDropdown: false, options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'], className: undefined, component: undefined, dropdownClassName: undefined,}, fontSize: {
                                                                                        component: FontSizePicker, onChange: handleFontSizeChange,}, fontFamily: {
                                                                                        component: FontFamilyPicker, onChange: handleFontChange,}, list: {
                                                                                        inDropdown: false, className: undefined, component: undefined, dropdownClassName: undefined, options: ['unordered', 'ordered', 'indent', 'outdent'],}, textAlign: {
                                                                                        inDropdown: false, className: undefined, component: undefined, dropdownClassName: undefined, options: ['left', 'center', 'right', 'justify'],}, remove: {className: undefined, component: undefined }, history: {
                                                                                        inDropdown: false, className: undefined, component: undefined, dropdownClassName: undefined, options: ['undo', 'redo'], undo: { className: undefined }, redo: { className: undefined },},}}
                                                                            wrapperClassName="wrapper-class"
                                                                            editorClassName="editor-class"
                                                                            toolbarClassName="toolbar-class"
                                                                        />*/}
                                                                        <Editor
                                                                            editorState={editorState}
                                                                            onEditorStateChange={setEditorState}
                                                                            toolbar={
                                                                                {
                                                                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history'],
                                                                                    inline: {
                                                                                        inDropdown: false,
                                                                                        className: undefined,
                                                                                        component: undefined,
                                                                                        dropdownClassName: undefined,
                                                                                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
                                                                                        bold: {className: undefined},
                                                                                        italic: {className: undefined},
                                                                                        underline: {className: undefined},
                                                                                        strikethrough: {className: undefined},
                                                                                        monospace: {className: undefined},
                                                                                        superscript: {className: undefined},
                                                                                        subscript: {className: undefined},
                                                                                    },
                                                                                    blockType: {
                                                                                        inDropdown: true,
                                                                                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                                                                        className: undefined,
                                                                                        component: undefined,
                                                                                        dropdownClassName: undefined,
                                                                                    },
                                                                                    fontSize: {
                                                                                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                                                                        className: undefined,
                                                                                        component: undefined,
                                                                                        dropdownClassName: undefined,
                                                                                    },
                                                                                    fontFamily: {
                                                                                        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                                                                        className: undefined,
                                                                                        component: undefined,
                                                                                        dropdownClassName: undefined,
                                                                                    },
                                                                                    list: {
                                                                                        inDropdown: false,
                                                                                        className: undefined,
                                                                                        component: undefined,
                                                                                        dropdownClassName: undefined,
                                                                                        options: ['unordered', 'ordered', 'indent', 'outdent'],
                                                                                        unordered: {className: undefined},
                                                                                        ordered: {className: undefined},
                                                                                        indent: {className: undefined},
                                                                                        outdent: {className: undefined},
                                                                                    },
                                                                                    textAlign: {
                                                                                        inDropdown: false,
                                                                                        className: undefined,
                                                                                        component: undefined,
                                                                                        dropdownClassName: undefined,
                                                                                        options: ['left', 'center', 'right', 'justify'],
                                                                                        left: {className: undefined},
                                                                                        center: {className: undefined},
                                                                                        right: {className: undefined},
                                                                                        justify: {className: undefined},
                                                                                    },
                                                                                    colorPicker: {
                                                                                        className: undefined,
                                                                                        component: undefined,
                                                                                        popupClassName: undefined,
                                                                                        colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                                                                                            'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                                                                                            'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                                                                                            'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                                                                                            'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                                                                                            'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                                                                                    },
                                                                                    link: {
                                                                                        inDropdown: false,
                                                                                        className: undefined,
                                                                                        component: undefined,
                                                                                        popupClassName: undefined,
                                                                                        dropdownClassName: undefined,
                                                                                        showOpenOptionOnHover: true,
                                                                                        defaultTargetOption: '_self',
                                                                                        options: ['link', 'unlink'],
                                                                                        link: {className: undefined},
                                                                                        unlink: {className: undefined},
                                                                                        linkCallback: undefined
                                                                                    },
                                                                                    emoji: {
                                                                                        className: undefined,
                                                                                        component: undefined,
                                                                                        popupClassName: undefined,
                                                                                        emojis: [
                                                                                            '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                                                                            '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                                                                            '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                                                                            '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                                                                            '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                                                                            '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                                                                            '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                                                                            '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                                                                            '✅', '❎', '💯',
                                                                                        ],
                                                                                    },
                                                                                    image: {
                                                                                        className: undefined,
                                                                                        component: undefined,
                                                                                        popupClassName: undefined,
                                                                                        urlEnabled: true,
                                                                                        uploadEnabled: true,
                                                                                        alignmentEnabled: true,
                                                                                        uploadCallback: uploadCallback,
                                                                                        previewImage: true,
                                                                                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                                                        alt: {present: false, mandatory: false},
                                                                                        defaultSize: {
                                                                                            height: 'auto',
                                                                                            width: 'auto',
                                                                                        },
                                                                                    },
                                                                                    remove: {className: undefined, component: undefined},
                                                                                    history: {
                                                                                        inDropdown: false,
                                                                                        className: undefined,
                                                                                        component: undefined,
                                                                                        dropdownClassName: undefined,
                                                                                        options: ['undo', 'redo'],
                                                                                        undo: {className: undefined},
                                                                                        redo: {className: undefined},
                                                                                    },
                                                                                }
                                                                            }
                                                                            wrapperClassName="wrapper-class"
                                                                            editorClassName="editor-class"
                                                                            toolbarClassName="toolbar-class"
                                                                        />
                                                                        <Form.Item>
                                                                            <Space style={{float:'right'}}>
                                                                                <Button type='primary' htmlType='submit' disabled={!editorState.getCurrentContent().hasText()}>
                                                                                    Submit
                                                                                </Button>
                                                                                <Button danger  disabled={loading}  onClick={() => setModalAddOpen(false)} >
                                                                                    <i className="fa fa-times" aria-hidden="true"></i> cancel
                                                                                </Button>

                                                                            </Space>
                                                                        </Form.Item>
                                                                    </>
                                                                }
                                                                {
                                                                    paragraphType==='IMAGE' && <>
                                                                        <input type="file" accept="image/png" onChange={getFiles}/>
                                                                        <hr />
                                                                        <textarea onChange={handleOnChangeDescription} id="description" cols={"50"} rows={'5'}></textarea><br/>
                                                                        <hr />
                                                                    <br />

                                                                        <Form.Item>
                                                                            <Space style={{float:'right'}}>
                                                                                <Button type='primary' htmlType='submit' disabled={addBTN}>
                                                                                    Submit
                                                                                </Button>
                                                                                <Button danger  disabled={loading}  onClick={() => setModalAddOpen(false)} >
                                                                                    <i className="fa fa-times" aria-hidden="true"></i> cancel
                                                                                </Button>

                                                                            </Space>
                                                                        </Form.Item>
                                                                    </>
                                                                }</>
                                                        }
                                                        {
                                                            paragraphType ==='' && <Button danger  onClick={() => setModalAddOpen(false)} >
                                                                <i className="fa fa-times" aria-hidden="true"></i> cancel
                                                            </Button>
                                                        }
                                                        </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </section>
                        </>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}