import React, {useEffect, useRef, useState} from 'react';
import {fabric} from "fabric";
import {Button, ColorPicker, Form, Input, Select, Space} from "antd";
import {toastClick} from "../../../components/toast/CustomToast";
import BreadCom from "../../../components/breadCom/BreadCom";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {useSelector} from "react-redux";
import {CERTIFICAT} from "../../../components/utils/Constantes";
import LoadingPage from "../../../components/loadingPage/LoadingPage";

function AddCertificate() {
    const {accessToken} = useSelector(state => state.authUser);
    const canvasRef = useRef(null);
    const [canvas, setCanvas] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [name, setName] = useState(false);
    const [formation, setFormation] = useState(false);
    const [images, setImages] = useState(false);
    const [texts, setTexts] = useState(false);
    const [lines, setLines] = useState(false);


    // Initialize the canvas once the component is mounted
    useEffect(() => {
        const newCanvas = new fabric.Canvas(canvasRef.current, {
            width: 841,
            height: 595,
            backgroundColor: '#f0f0f0',
            selection:true,
        });

        setCanvas(newCanvas);

        return () => {
            newCanvas.dispose();
        };
    }, []);
    const deleteSelectedObject = () => {
        if (canvas) {
            const activeObject = canvas?.getActiveObject();
            if (activeObject) {
                canvas.remove(activeObject);
            } else {
                toastClick('Please select an object to delete',"info");
            }
        }
    };
    const certificateName = ()=>{
        let name = prompt("Nom du certificat ?", null);
        if (name==null) {
            certificateName();
        } else {
            return name;
        }

    }
    const saveCanvasObject = () => {
        if (canvas) {
            if(canvas.getObjects().length>=1){
                setLoading(true);
                let name = certificateName();
                const dat ={
                    id:null,
                    contenu:JSON.stringify(canvas.toJSON(['id']), null, 2),
                    name: name,
                    status:false
                }
                axiosInstance(accessToken).post(
                    "api/certificate/save",dat
                ).then((result)=> {
                    toastClick("Done ...", "success");
                    setLoading(false);
                    window.location.href=CERTIFICAT;
                }).catch((result)=>{
                    setLoading(false);
                    toastClick(result.data.message, "error");
                });
            }else {
                toastClick('Please your canvas is empty',"info");
            }
        }else {
            toastClick('Please your canvas is empty',"info");
        }
    };
    const addLocalImage = (event) => {
        const file = event.target.files?.[0];
        if (file && canvas) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const imageUrl = e.target?.result;
                fabric.Image.fromURL(imageUrl, (img) => {
                    img.set({
                        left: 100,
                        top: 100,
                        scaleX: 0.5,
                        scaleY: 0.5,
                        id:'image-'+canvas.getObjects().length
                    });
                    canvas.add(img);
                });
            };
            reader.readAsDataURL(file);
        }
    };
    const findElementById = () => {
        if(selectedId!=null){
            if(canvas)
                return canvas.getObjects().find(obj => obj.id == selectedId);
            else
                return null;
        }else {
            return null;
        }
    };
    const setColor = (e)=>{
        setSelectedColor(e);
    }
    const onSubmitTitle = async (values) => {
        if (canvas) {
            let textbox = findElementById();
            if(!textbox){
                textbox = new fabric.Textbox(values.title, {
                    left: 100,
                    top: 100,
                    width: 200,
                    fontSize: parseFloat(values.fontSize),
                    fontFamily: values.fontFamily,
                    fontWeight: values.fontWeight,
                    fill: `${values.fill.toHexString()}`,
                    editable: true,
                    id: 'text'+canvas.getObjects().length
                });
                canvas.add(textbox);
                canvas.setActiveObject(textbox);
            }else {
                try {
                    textbox.set('fill', `${selectedColor.toHexString()}`)
                }catch (e) {
                }
                textbox.set('fontWeight', values.fontWeight)
                textbox.set('fontFamily', values.fontFamily)
                textbox.set('fontSize', parseFloat(values.fontSize))
                textbox.set('text', values.title)
            }

            textbox.on('selected', () => {
                if (textbox && canvas) {
                    setSelectedId(textbox.id);
                    form.setFieldsValue({
                        title: textbox.text,
                        fontSize: textbox.get('fontSize'),
                        fontFamily: textbox.get('fontFamily'),
                        fontWeight: textbox.get('fontWeight'),
                        fill: textbox.get('fill')

                    });
                    addTexts();
                    canvas.renderAll(); // Re-render the canvas
                }
            });
            textbox.on('deselected', () => {
                if (textbox && canvas) {
                    setSelectedId(null);
                    setSelectedColor(null);
                    form.resetFields();
                }
            });
            canvas.renderAll();
        }
        form.resetFields();
    }
    const preview = () => {
        if (canvas) {
            canvas.discardActiveObject();
            canvas.renderAll();
            form.resetFields();
            setName(false)
            setTexts(false)
            setImages(false)
            setLines(false)
            setFormation(false)
        }
    }
    const onSubmitName = async (values) => {
        if (canvas) {
            let textbox = canvas.getObjects().find(obj => obj.id == "name")
            if(!textbox){
                textbox = new fabric.Textbox(values.title, {
                    left: 100,
                    top: 100,
                    width: 200,
                    fontSize: parseFloat(values.fontSize),
                    fontFamily: values.fontFamily,
                    fontWeight: values.fontWeight,
                    fill: `${values.fill.toHexString()}`,
                    editable: true,
                    id: 'name'
                });
                canvas.add(textbox);
                canvas.setActiveObject(textbox);
            }else {
                try{
                    textbox.set('fill', `${values.fill.toHexString()}`)
                }catch (e) {

                }
                textbox.set('fontWeight', values.fontWeight)
                textbox.set('fontFamily', values.fontFamily)
                textbox.set('fontSize', parseFloat(values.fontSize))
            }
            textbox.on('selected', () => {
                if (textbox && canvas) {
                    form.setFieldsValue({
                        title: textbox.text,
                        fontSize: textbox.get('fontSize'),
                        fontFamily: textbox.get('fontFamily'),
                        fontWeight: textbox.get('fontWeight'),
                        fill: textbox.get('fill')

                    });
                    addName();
                    canvas.renderAll(); // Re-render the canvas
                }
            });
            textbox.on('deselected', () => {
                if (textbox && canvas) {
                    setSelectedColor(null);
                    form.resetFields();
                }
            });
            canvas.renderAll();
        }
        form.resetFields();
    }
    const onSubmitFormation = async (values) => {
        if (canvas) {
            let textbox = canvas.getObjects().find(obj => obj.id == "formation")
            if(!textbox){
                textbox = new fabric.Textbox(values.title, {
                    left: 100,
                    top: 100,
                    width: 200,
                    fontSize: parseFloat(values.fontSize),
                    fontFamily: values.fontFamily,
                    fontWeight: values.fontWeight,
                    fill: `${values.fill.toHexString()}`,
                    editable: true,
                    id: 'formation'
                });
                canvas.add(textbox);
                canvas.setActiveObject(textbox);
            }else {
                try{
                    textbox.set('fill', `${values.fill.toHexString()}`)
                }catch (e) {

                }
                textbox.set('fontWeight', values.fontWeight)
                textbox.set('fontFamily', values.fontFamily)
                textbox.set('fontSize', parseFloat(values.fontSize))
            }
            textbox.on('selected', () => {
                if (textbox && canvas) {
                    form.setFieldsValue({
                        title: textbox.text,
                        fontSize: textbox.get('fontSize'),
                        fontFamily: textbox.get('fontFamily'),
                        fontWeight: textbox.get('fontWeight'),
                        fill: textbox.get('fill')
                    });
                    addFormation();
                    canvas.renderAll(); // Re-render the canvas
                }
            });
            textbox.on('deselected', () => {
                if (textbox && canvas) {
                    setSelectedColor(null);
                    form.resetFields();
                }
            });
            canvas.renderAll();
        }
        form.resetFields();
    }
    const addName = () => {
        setName(true)
        setTexts(false)
        setImages(false)
        setFormation(false)
        setLines(false)
    };
    const addFormation = () => {
        setFormation(true)
        setName(false)
        setTexts(false)
        setImages(false)
        setLines(false)
    };
    const addTexts = () => {
        setName(false)
        setLines(false)
        setTexts(true)
        setImages(false)
        setFormation(false)
    };
    const addLines = () => {
        setName(false)
        setLines(true)
        setTexts(false)
        setImages(false)
        setFormation(false)
    };
    const addNameInit = () => {
        setName(true)
        setTexts(false)
        setImages(false)
        setFormation(false)
        setLines(false)
        form.resetFields();
    };
    const addFormationInit = () => {
        setFormation(true)
        setName(false)
        setTexts(false)
        setImages(false)
        setLines(false)
        form.resetFields();
    };
    const addImagesInit = () => {
        setName(false)
        setTexts(false)
        setImages(true)
        setFormation(false)
        setLines(false)
        form.resetFields();
    };
    const addTextsInit = () => {
        setName(false)
        setLines(false)
        setTexts(true)
        setImages(false)
        setFormation(false)
        form.resetFields();
    };
    const addLinesInit = () => {
        setName(false)
        setLines(true)
        setTexts(false)
        setImages(false)
        setFormation(false)
        form.resetFields();
    };
    const onSubmitLine = async (values) =>{
        if (canvas) {
            let ligne = findElementById();
            if(!ligne){
                ligne = new fabric.Line([50, 50, 200, 200], {
                    stroke: `${values.stroke.toHexString()}`,
                    strokeWidth: parseFloat(values.strokeWidth),
                    selectable: true,
                    id: 'line-'+canvas.getObjects().length
                });
                canvas.add(ligne);
            }else {
                try {
                    ligne.set('stroke', `${selectedColor.toHexString()}`)
                }catch (e) {
                }
                ligne.set('strokeWidth', parseFloat(values.strokeWidth))
            }

            ligne.on('selected', () => {
                if (ligne && canvas) {
                    setSelectedId(ligne.id);
                    form.setFieldsValue({
                        stroke: ligne.get('stroke'),
                        strokeWidth: ligne.get('strokeWidth')
                    });
                    addLines();
                    canvas.renderAll(); // Re-render the canvas
                }
            });
            ligne.on('deselected', () => {
                if (ligne && canvas) {
                    setSelectedId(null);
                    setSelectedColor(null);
                }
            });
            canvas.renderAll();
        }
        form.resetFields();
    }

    const breadCum = [
        {
            id:0,
            last:false,
            path:'/admin/certificate',
            name:'Certificats',
        },
        {
            id:1,
            last:true,
            path:'',
            name:'Ajouter',
        }
    ];

    return (
        <>
            <BreadCom name={"Certificat"} data={breadCum} />
            <section className="content ">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Certificat
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        {
                                            loading && <LoadingPage />
                                        }
                                        <div style={
                                            {
                                                display: loading ? 'none' : "block"
                                            }
                                        }>
                                            <Button onClick={()=>addTextsInit()}>Ajouter un paragraphe</Button>
                                            <Button onClick={()=>addNameInit()}>Ajouter La zone du nom</Button>
                                            <Button onClick={()=>addFormationInit()}>Ajouter Les informations Sur la formation</Button>
                                            <Button onClick={()=>addImagesInit()}>Ajouter une image</Button>
                                            <Button onClick={()=>addLinesInit()}>Ajouter une Ligne</Button>
                                            <Button onClick={deleteSelectedObject}>Delete Selected</Button>
                                            <Button onClick={()=>{preview()}}>Previsualiser</Button>
                                            <Button onClick={()=>{saveCanvasObject()}}>Enregistrer</Button>
                                            <div className="flex h-100 space-x-4 p-4">
                                                    <div className="w-[841px] h-[595px] relative">
                                                        <canvas ref={canvasRef}></canvas>
                                                    </div>
                                                    <div className={`p-4 bg-green-500 text-white rounded-md cursor-pointer `}>
                                                        <div>
                                                            {
                                                                lines && <>
                                                                    <h1>Ligne</h1>
                                                                    <hr />
                                                                    <br />
                                                                    <Form onFinish={onSubmitLine} form={form}>
                                                                        <Form.Item
                                                                            label="Épaisseur de la ligne"
                                                                            name="strokeWidth"
                                                                            rules={
                                                                                [
                                                                                    { required: true, message: 'Entrez l\'epaisseur de la ligne' },
                                                                                    {
                                                                                        pattern: /^[+]?\d*\.?\d+$/, // Positive number regex pattern
                                                                                        message: 'Veuillez entrer un nombre positif valide.',
                                                                                    },
                                                                                ]}
                                                                            hasFeedback>
                                                                            <Input type="number" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Couleur de la ligne"
                                                                            name="stroke"
                                                                            rules={
                                                                                [
                                                                                    { required: true, message: "Veuillez choisir la couleur de votre ligne" }
                                                                                ]}
                                                                            hasFeedback>
                                                                            <ColorPicker onChange={setColor} showText format={"hex"} />
                                                                        </Form.Item>
                                                                        <Form.Item>
                                                                            <Space style={{float:'right'}}>
                                                                                <Button type="primary" htmlType="submit">
                                                                                    Submit
                                                                                </Button>
                                                                            </Space>
                                                                        </Form.Item>
                                                                    </Form>
                                                                </>
                                                            }
                                                            {
                                                                texts && <>
                                                                    <h1>Text</h1>
                                                                    <hr />
                                                                    <br />
                                                                    <Form onFinish={onSubmitTitle} form={form}>
                                                                        <Form.Item
                                                                            label="Titre"
                                                                            name="title"
                                                                            rules={
                                                                                [
                                                                                    { required: true, message: 'Veuillez entrer le titre du certificat' },
                                                                                    {type:"string", message:""}
                                                                                ]}
                                                                            hasFeedback>
                                                                            <Input />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Taille"
                                                                            name="fontSize"
                                                                            rules={
                                                                                [
                                                                                    { required: true, message: 'Selectionner la Categorie' },
                                                                                    {
                                                                                        pattern: /^[+]?\d*\.?\d+$/, // Positive number regex pattern
                                                                                        message: 'Veuillez entrer un nombre positif valide.',
                                                                                    },
                                                                                ]}
                                                                            hasFeedback
                                                                        >
                                                                            <Input type="number" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Police"
                                                                            name="fontFamily"
                                                                            rules={
                                                                                [
                                                                                    { required: true, message: "Veuillez choisir une police" }
                                                                                ]}
                                                                            hasFeedback>
                                                                            <Select placeholder="Veuillez choisir une police">
                                                                                <Select.Option value="Arial">Arial</Select.Option>
                                                                                <Select.Option value="Verdana">Verdana</Select.Option>
                                                                                <Select.Option value="Courier">Courier</Select.Option>
                                                                                <Select.Option value="Georgia">Georgia</Select.Option>
                                                                                <Select.Option value="Times New Roman">Times New Roman</Select.Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Poid"
                                                                            name="fontWeight"
                                                                            rules={
                                                                                [
                                                                                    { required: true, message: "Veuillez choisir le poid de votre police" }
                                                                                ]}
                                                                            hasFeedback>
                                                                            <Select placeholder="Veuillez choisir le poid de votre police">
                                                                                <Select.Option value="normal">Normal</Select.Option>
                                                                                <Select.Option value="bold">Bold</Select.Option>
                                                                                <Select.Option value="bolder">Bolder</Select.Option>
                                                                                <Select.Option value="lighter">Lighter</Select.Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Text Color"
                                                                            name="fill"
                                                                            rules={
                                                                                [
                                                                                    { required: true, message: "Veuillez choisir la couleur de votre text" }
                                                                                ]}
                                                                            hasFeedback>
                                                                            <ColorPicker onChange={setColor} showText format={"hex"} />
                                                                        </Form.Item>
                                                                        <Form.Item>
                                                                            <Space style={{float:'right'}}>
                                                                                <Button type="primary" htmlType="submit">
                                                                                    Submit
                                                                                </Button>
                                                                            </Space>
                                                                        </Form.Item>
                                                                    </Form>
                                                                </>
                                                            }
                                                            {
                                                                images && <>
                                                                    <h1>Images</h1>
                                                                    <hr />
                                                                    <br />
                                                                    <input
                                                                        type="file"
                                                                        accept="image/*"
                                                                        onChange={addLocalImage}
                                                                    />
                                                                </>


                                                            }
                                                            {
                                                                name && <>
                                                                    <h1>Zone de nom</h1>
                                                                    <hr />
                                                                    <br />
                                                                    <Form onFinish={onSubmitName} form={form}>
                                                                        <Form.Item
                                                                            label="Titre"
                                                                            name="title"
                                                                            rules={
                                                                                [
                                                                                    { required: true, message: 'Veuillez entrer le titre du certificat' },
                                                                                    {type:"string", message:""}
                                                                                ]}
                                                                            hasFeedback>
                                                                            <Input />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Taille"
                                                                            name="fontSize"
                                                                            rules={
                                                                                [
                                                                                    { required: true, message: 'Selectionner la Categorie' },
                                                                                    {
                                                                                        pattern: /^[+]?\d*\.?\d+$/, // Positive number regex pattern
                                                                                        message: 'Veuillez entrer un nombre positif valide.',
                                                                                    },
                                                                                ]}
                                                                            hasFeedback
                                                                        >
                                                                            <Input type="number" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Police"
                                                                            name="fontFamily"
                                                                            rules={
                                                                                [
                                                                                    { required: true, message: "Veuillez choisir une police" }
                                                                                ]}
                                                                            hasFeedback>
                                                                            <Select placeholder="Veuillez choisir une police">
                                                                                <Select.Option value="Arial">Arial</Select.Option>
                                                                                <Select.Option value="Verdana">Verdana</Select.Option>
                                                                                <Select.Option value="Courier">Courier</Select.Option>
                                                                                <Select.Option value="Georgia">Georgia</Select.Option>
                                                                                <Select.Option value="Times New Roman">Times New Roman</Select.Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Poid"
                                                                            name="fontWeight"
                                                                            rules={
                                                                                [
                                                                                    { required: true, message: "Veuillez choisir le poid de votre police" }
                                                                                ]}
                                                                            hasFeedback>
                                                                            <Select placeholder="Veuillez choisir le poid de votre police">
                                                                                <Select.Option value="normal">Normal</Select.Option>
                                                                                <Select.Option value="bold">Bold</Select.Option>
                                                                                <Select.Option value="bolder">Bolder</Select.Option>
                                                                                <Select.Option value="lighter">Lighter</Select.Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Text Color"
                                                                            name="fill"
                                                                            rules={
                                                                                [
                                                                                    { required: true, message: "Veuillez choisir la couleur de votre text" }
                                                                                ]}
                                                                            hasFeedback>
                                                                            <ColorPicker onChange={setColor} showText format={"hex"} />
                                                                        </Form.Item>
                                                                        <Form.Item>
                                                                            <Space style={{float:'right'}}>
                                                                                <Button type="primary" htmlType="submit">
                                                                                    Submit
                                                                                </Button>
                                                                            </Space>
                                                                        </Form.Item>
                                                                    </Form>
                                                                </>
                                                            }
                                                            {
                                                                formation && <>
                                                                    <h1>Zone Pour le nom de la formation</h1>
                                                                    <hr />
                                                                    <br />
                                                                    <Form onFinish={onSubmitFormation} form={form}>
                                                                        <Form.Item
                                                                            label="Titre"
                                                                            name="title"
                                                                            rules={
                                                                                [
                                                                                    { required: true, message: 'Veuillez entrer le titre du certificat' },
                                                                                    {type:"string", message:""}
                                                                                ]}
                                                                            hasFeedback>
                                                                            <Input />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Taille"
                                                                            name="fontSize"
                                                                            rules={
                                                                                [
                                                                                    { required: true, message: 'Selectionner la Categorie' },
                                                                                    {
                                                                                        pattern: /^[+]?\d*\.?\d+$/, // Positive number regex pattern
                                                                                        message: 'Veuillez entrer un nombre positif valide.',
                                                                                    },
                                                                                ]}
                                                                            hasFeedback
                                                                        >
                                                                            <Input type="number" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Police"
                                                                            name="fontFamily"
                                                                            rules={
                                                                                [
                                                                                    { required: true, message: "Veuillez choisir une police" }
                                                                                ]}
                                                                            hasFeedback>
                                                                            <Select placeholder="Veuillez choisir une police">
                                                                                <Select.Option value="Arial">Arial</Select.Option>
                                                                                <Select.Option value="Verdana">Verdana</Select.Option>
                                                                                <Select.Option value="Courier">Courier</Select.Option>
                                                                                <Select.Option value="Georgia">Georgia</Select.Option>
                                                                                <Select.Option value="Times New Roman">Times New Roman</Select.Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Poid"
                                                                            name="fontWeight"
                                                                            rules={
                                                                                [
                                                                                    { required: true, message: "Veuillez choisir le poid de votre police" }
                                                                                ]}
                                                                            hasFeedback>
                                                                            <Select placeholder="Veuillez choisir le poid de votre police">
                                                                                <Select.Option value="normal">Normal</Select.Option>
                                                                                <Select.Option value="bold">Bold</Select.Option>
                                                                                <Select.Option value="bolder">Bolder</Select.Option>
                                                                                <Select.Option value="lighter">Lighter</Select.Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Text Color"
                                                                            name="fill"
                                                                            rules={
                                                                                [
                                                                                    { required: true, message: "Veuillez choisir la couleur de votre text" }
                                                                                ]}
                                                                            hasFeedback>
                                                                            <ColorPicker onChange={setColor} showText format={"hex"} />
                                                                        </Form.Item>
                                                                        <Form.Item>
                                                                            <Space style={{float:'right'}}>
                                                                                <Button type="primary" htmlType="submit">
                                                                                    Submit
                                                                                </Button>
                                                                            </Space>
                                                                        </Form.Item>
                                                                    </Form>
                                                                </>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AddCertificate

