import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal, Select, Space, message, Spin} from 'antd';
import { useSelector } from "react-redux";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {useRole} from "../../../components/hook";

export default function AddSlider() {
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    const {accessToken} = useSelector(state => state.authUser);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [form] = Form.useForm();
    const openModal =()=>{
        form.resetFields();
        setLoading(false);
        setModalAddOpen(true);
        setFile(null);
    }
    const onSubmit = (values) => {
        if(file === null){
            message.error('Choisir l\'image de la slide');
        }else {
            setLoading(true);
            const dat = {
                message:values.message,
                name:values.name,
                status:false,
                photo:null,
            };
            const formData = new FormData();
            formData.append('file', file);
            formData.append('jsonData', JSON.stringify(dat));
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            axiosInstance(accessToken).post(
                "api/sliders/save",
                formData,config
            ).then(
                (result)=> {
                    message.success('Slider Create');
                    setTimeout(
                        ()=>{
                            form.resetFields();
                            setLoading(false);
                            window.location.reload();
                            setModalAddOpen(false);
                        },2000
                    )
                }).catch((error)=> {
                setLoading(false);
                message.error(error.response.data.error);
            });
        }

    };
    const getFiles = (event)=>{
        if(event.target.files.length>0) {
            setFile(event.target.files[0]);
        }
    }

    return(
        <>
            <Button style={{float: "right"}}  onClick={openModal} >
                <i className="fa fa-plus" aria-hidden="true"></i>Ajouter
            </Button>
            <Modal
                title="Creation d'une slide"
                style={{
                    top: 20,
                }}
                width={1000}
                open={modalAddOpen}
                maskClosable={false}
                closable={false}
                footer={[]}
            >
                <Spin spinning={loading}>
                    <Form onFinish={onSubmit} form={form}>
                        <Form.Item
                            label="Nom"
                            name="name"
                            rules={
                                [
                                    { required: true, message: 'Veuillez entrer le nom' },
                                    {type:"string", message:"Entrer un nom valide !"}
                                ]}
                            hasFeedback>
                            <Input placeholder="entrer le nom"/>
                        </Form.Item>
                        <Form.Item
                            label="Message"
                            name="message">
                            <Input placeholder="Veuillez entrer le message"/>
                        </Form.Item>
                        <Form.Item
                            label="Fichier"
                            hasFeedback>
                            <input type="file" accept="image/png" onChange={getFiles}/>
                        </Form.Item>
                        <Form.Item>
                            <Space style={{float:'right'}}>
                                <Button type='primary' htmlType='submit' disabled={loading}>
                                    Submit
                                </Button>
                                <Button danger  disabled={loading}  onClick={() => setModalAddOpen(false)} >
                                    <i className="fa fa-times" aria-hidden="true"></i> cancel
                                </Button>

                            </Space>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}