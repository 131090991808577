import React, { useState, useEffect } from 'react';
import {Button, Form, Input, Modal, Space, message, Upload, Select} from 'antd';
import {PlusCircleFilled, UploadOutlined} from "@ant-design/icons";
import { EditorState, convertToRaw, RichUtils } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './Add.css';
import { useSelector } from "react-redux";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {useRole} from "../../../components/hook";


export default function AddArticle({action, style}) {
  const {accessToken} = useSelector(state => state.authUser);
  const [form] = Form.useForm();
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty()
  );
  useRole(['ROOT','ADMIN','SUPERADMIN']);

  const [file, setFile] = useState(null);
  const getFiles = (event)=>{
    if(event.target.files.length>0) {
      setFile(event.target.files[0]);
    }
  }
  const uploadCallback = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({ data: { link: e.target.result } }); // URL de l'image
      };
      reader.onerror = (e) => reject(e);
      reader.readAsDataURL(file);
    });
  };

  const customToolBar = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history'],
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
      bold: {className: undefined},
      italic: {className: undefined},
      underline: {className: undefined},
      strikethrough: {className: undefined},
      monospace: {className: undefined},
      superscript: {className: undefined},
      subscript: {className: undefined},
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    fontSize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    fontFamily: {
      options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    list: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['unordered', 'ordered', 'indent', 'outdent'],
      unordered: {className: undefined},
      ordered: {className: undefined},
      indent: {className: undefined},
      outdent: {className: undefined},
    },
    textAlign: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['left', 'center', 'right', 'justify'],
      left: {className: undefined},
      center: {className: undefined},
      right: {className: undefined},
      justify: {className: undefined},
    },
    colorPicker: {
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
        'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
        'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
        'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
        'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
        'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
    },
    link: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      dropdownClassName: undefined,
      showOpenOptionOnHover: true,
      defaultTargetOption: '_self',
      options: ['link', 'unlink'],
      link: {className: undefined},
      unlink: {className: undefined},
      linkCallback: undefined
    },
    emoji: {
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      emojis: [
        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
        '✅', '❎', '💯',
      ],
    },
    image: {
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
      uploadCallback: uploadCallback,
      previewImage: true,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      alt: {present: false, mandatory: false},
      defaultSize: {
        height: 'auto',
        width: 'auto',
      },
    },
    remove: {className: undefined, component: undefined},
    history: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['undo', 'redo'],
      undo: {className: undefined},
      redo: {className: undefined},
    },
  };

  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [loading, setLoading] = useState(false);
const openModal =()=>{
  setLoading(false);
  setFile(null);
  form.resetFields();
  setModalAddOpen(true);
}

  const onClose = () => {
    form.resetFields();
    setLoading(true)
    setEditorState(() => EditorState.createEmpty());
    setModalAddOpen(false);

  }
  const onSubmit = async (values) => {
    if(editorState.getCurrentContent().getPlainText().trim().length!=0){
      if(file === null){
        message.error('Choisir le logo du centre partenaire');
      }else {
        setLoading(true);
        const dat ={
          "id":null,
          "title":values.title,
          "author":values.author,
          "contenu":JSON.stringify(convertToRaw(editorState.getCurrentContent())),
          "categorie":values.categorie,
          "typeDonne":"ARTICLE",
          "status":false,
          "phote":null,
          "lue":0,
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('jsonData', JSON.stringify(dat));
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        axiosInstance(accessToken).post(
            "api/article",
            formData, config
        ).then((result)=> {
          message.info("Done ...");
          setModalAddOpen(false);
          window.location.reload();
          setLoading(false);
        }).catch((result)=>{
          setLoading(false);
          message.error(result.data.message)
        });
      }

    }else{
      message.error("Le contenu de l'article est vide")
    }
  }

  return(
    <>
      <Button style={{float: "right"}}  onClick={openModal} >
        <i aria-hidden="true"><PlusCircleFilled /></i> {action}
          </Button>
          <Modal
          title="Creation d'un article"
          style={{
            top: 20,
          }}
          width={1000}
          open={modalAddOpen}
          maskClosable={false}
          closable={false}
          footer={[]}
        >
          <Form onFinish={onSubmit} form={form}>
            <Form.Item
              label="Titre"
              name="title"
              rules={
                [
                  { required: true, message: 'Veuillez entrer le titre' },
                  {type:"string", message:""}
                ]}
              hasFeedback>
              <Input />
            </Form.Item>
            <Form.Item
              label="CATEGORIE"
              name="categorie"
              rules={
                [
                  { required: true, message: 'Selectionner la Categorie' }
                ]}
              hasFeedback>
              <Select placeholder="Choisir la Categorie">
                <Select.Option value="SANTE_SEXUELLE">SANTE SEXUELLE</Select.Option>
                <Select.Option value="IVG">INTERRUPTION VOLONTAIRE GROSSESE</Select.Option>
                <Select.Option value="PLANNING_FAMILLIAL">PLANNING FAMILLIAL</Select.Option>
                <Select.Option value="VIH_IST">VIH/IST</Select.Option>
                <Select.Option value="HYGENE_MENSTRUELLE">HYGENE MENSTRUELLE</Select.Option>
                <Select.Option value="VVG">VIOLENCE BASE SUR LE GENRE</Select.Option>
                <Select.Option value="GROSSESSE_PRECOSE">GROSSESSE PRECOSE</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Auteur"
              name="author"
              rules={
                [
                  { required: true, message: "Veuillez entrer le nom de l'auteur" },
                  {type:"string", message:"Entrer un libelle !"}
                ]}
              hasFeedback>
              <Input />
            </Form.Item>
            <Form.Item
              label="Contenu"
              name="contenu" style={{ position: 'relative' }}>
              <Editor
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  toolbar={customToolBar}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
              />
            </Form.Item>
            <Form.Item
                label="Fichier"
                hasFeedback>
              <input type="file" accept="image/png" onChange={getFiles}/>
            </Form.Item>
          <Form.Item>

            <Space style={{float:'right'}}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
              <Button danger loading={loading} onClick={onClose} >
                <i className="fa fa-times" aria-hidden="true"></i> cancel
              </Button>

            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}