import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import React, {useEffect, useState} from "react";
import {Empty, message, Spin} from "antd";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {EditorState, convertFromRaw} from "draft-js";
import './View.css';
import {useRole, useTitle} from "../../../components/hook/index";
import BreadCom from "../../../components/breadCom/BreadCom";

export default function ViewAPropos() {
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const nav = useNavigate();
    const [aPropos, setAPropos] = useState(null);
    const [loading, setLoading] = useState(true);
    const params = useParams();
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('View A Propos');
    const navigation = (link)=>{
        setLoading(true);
        nav(link);
        setLoading(false);
    }
    const getAProposById = () => {
        axiosInstance().get(
            "api/apropos/list"
        ).then((result)=> {
            if(result.data != null){
                setAPropos(result.data)
                setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(result.data.contenu))));
            }
            setLoading(false);
        }).catch((result)=> {
            setLoading(false);
        });
    }

    useEffect(
        ()=>{
            setLoading(true)
            getAProposById();
        },[]
    )
    const returnValue = aPropos ==null ? <>
        <Spin spinning={loading}>
            <Empty />
        </Spin>
    </>  : <>
        <Spin  spinning={loading}>
            <hr />
            <div style={{padding: "5px", marginTop: '5px', width: '100%',background: '#ffffff00'}}>
                <Editor
                    toolbarClassName="toolbar-class1"
                    readOnly={true}
                    editorState={editorState}
                    toolbar={
                        {
                            options: []
                        }
                    }
                />
            </div>
        </Spin>
    </>

    const breadCum = [
        {
            id:0,
            last:false,
            path:'/admin/apropos',
            name:'A Propos',
        },
        {
            id:1,
            last:true,
            path:params.id,
            name:' view / '+params.id,
        }
    ];
    return(
        <>
            <BreadCom name={"A Propos update"} data={breadCum}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Detail
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        {returnValue}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}