import {FormattedMessage} from "react-intl";
import {Empty, Spin} from "antd";

export default function Tableau({columns=[], renderRow, data=[], spinner=true}){
    return (
        <>
            {
                (data.length === 0) ? <Spin spinning={spinner}><Empty /></Spin> : <Spin spinning={spinner}>
                    <table className="w-full mt-4">
                        <thead>
                        <tr className="text-left text-gray-500 text-sm">
                            {columns.map((col) => (
                                <th key={col.accessor} className={col.className}>{col.accessor}</th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>{data.map((item) => renderRow(item))}</tbody>
                    </table>
                </Spin>
            }
        </>
    );
};
