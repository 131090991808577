import {Outlet, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {Button, Col, Empty, Form, Input, Row, Select, Space, Spin} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {toastClick} from "../../../components/toast/CustomToast";
import BreadCom from "../../../components/breadCom/BreadCom";
import {LoadingContext} from "../../../context/LoadingContext";
import LoadingPage from "../../../components/loadingPage/LoadingPage";

export default function UpdateParametre(){
    const params = useParams();
    const {accessToken} = useSelector(state => state.authUser);
    const nav = useNavigate();
    const [form] = Form.useForm();
    const [parametre, setParametre] = useState(null);
    const [spinner, setSpinner] = useState(true);
    const {loading, setLoading} = useContext(LoadingContext)
    useRole(['ROOT','SUPERADMIN']);
    useTitle('MAJ du Parametre');
    const navigation = (link)=>{
        setSpinner(true);
        nav(link);
        setSpinner(false);
    }
    const [selectedValue, setSelectedValue] = useState(null);
    const handleSelectChange = (value) => {
        setSelectedValue(value);
    };
    const breadCum = [
        {
            id:0,
            last:false,
            path:'/admin/parametre',
            name:'Parametres',
        },
        {
            id:1,
            last:true,
            path:params.id,
            name:params.id,
        }
    ];
    const onUpdate = (values) => {
        setSpinner(true);
        const dat ={
            "id":parametre.id,
            "titre":values.titre,
            "valeur":values.valeur,
            "taille":values.taille,
            "paramTypeEnum":selectedValue,
            "status":parametre.status,
        }
        axiosInstance(accessToken).put(
            "api/parametres/"+parametre.id,
            dat,
        ).then(
            (result)=> {
                toastClick('Parametre '+dat.titre+' Update','success');
                form.resetFields();
                navigation('/admin/parametre');
                setSpinner(false);
            }).catch((result)=> {
            toastClick(result.response.data.message,'error');
            setSpinner(false);
        });
    }
    const getMissionById = () => {
        setLoading(true);
        axiosInstance(accessToken).get('api/parametres/find-by-id/'+parseInt(params.id))
            .then(
                (response)=>{
                    setParametre(response.data);
                    setSelectedValue(response.data.paramTypeEnum)
                    setLoading(false);
                }
            ).catch(
            (error)=>{
                toastClick(error.response.data.message,'error');
                setLoading(false);
            }
        )
    }

    useEffect(
        ()=>{
            setLoading(true)
            getMissionById();
        },[]
    )
    const returnValue = parametre ==null ? <>
        <Spin spinning={spinner}>
            <Empty />
        </Spin>
    </>  : <>
        <Spin  spinning={spinner}>
            <div>
            </div>
            <div>
                <Row >
                    <Col span={12}>
                        <h1 style={{alignContent: 'center'}}>Update Parametre</h1>

                        <Row style={{marginTop: '15px'}}>
                            <Col >
                                <Form onFinish={onUpdate} form={form} initialValues={parametre}>
                                    <Form.Item
                                        label="Titre"
                                        name="titre"
                                        rules={
                                            [
                                                { required: true, message: 'Veuillez entrer le titre' },
                                                {type:"string", message:""}
                                            ]}
                                        hasFeedback>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item

                                        label="Valeur"
                                        name="valeur"
                                        rules={
                                            [
                                                { required: true, message: "Veuillez entrer la valeur" },
                                                {type:"string", message:"Entrer un chiffre !"}
                                            ]}
                                        hasFeedback>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Taille"
                                        name="taille"
                                        rules={
                                            [
                                                { required: true, message: "Veuillez entrer la taille" },
                                                {type:"string", message:"Entrer une taille !"}
                                            ]}
                                        hasFeedback>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Type"
                                        name="paramTypeEnum">
                                        <Select  value={selectedValue} onChange={handleSelectChange} placeholder="Choisir le Type de Parametre">
                                            <Select.Option value="WHATSAPP">Numero WhatsApp</Select.Option>
                                            <Select.Option value="CALL">Numero pour les appels telephonique</Select.Option>
                                            <Select.Option value="SMS">Numero pour les sms</Select.Option>
                                            <Select.Option value="STATISTICS">Statistiques medicale</Select.Option>
                                            <Select.Option value="FACEBOOK">Lien Facebook</Select.Option>
                                            <Select.Option value="TWEETER">Lien X (Tweeter) de l'application</Select.Option>
                                            <Select.Option value="YOUTUBE">Lien YouTube de l'application</Select.Option>
                                            <Select.Option value="LOCALISATION">Carte de l'acceuil</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item>
                                        <Space style={{float:'right'}}>
                                            <Button type='primary' htmlType='submit' disabled={loading}>
                                                Submit
                                            </Button>
                                            <Button danger  disabled={spinner}  onClick={() => navigation('/admin/parametre')} >
                                                <i className="fa fa-times" aria-hidden="true"></i> cancel
                                            </Button>

                                        </Space>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <div>
                    <Outlet />
                </div>


            </div>
        </Spin>
    </>;

    return (
        <>
            <BreadCom name={"Parametre update"} data={breadCum}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Update
                                    </h3>
                                </div>
                                <div className="card-body">
                                    {
                                        loading ? <LoadingPage /> : <>
                                            <div className="tab-content p-0" style={
                                                {
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }
                                            }>
                                                {returnValue}
                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );


}