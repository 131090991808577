import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {Button, Empty, Form, Input, message, Select, Space, Spin, Tag, Tooltip} from "antd";
import BreadCom from "../../../components/breadCom/BreadCom";
import {Active} from "../../../components/active";
import TableTemplate from "../../../components/table/index";
import AddChapter from "../add/AddChapter";
import ViewChapter from "../view/ViewChapter";
import {InfoCircleFilled, PlusCircleFilled} from "@ant-design/icons";
import {ELEARNING} from "../../../components/utils/Constantes";
import AddParagraph from "../../paragraph/add/AddParagraph";

export default function ListChapter() {
    const params = useParams();
    const {accessToken} = useSelector(state => state.authUser);
    const [loading, setLoading] = useState(true);
    const nav = useNavigate();
    const [chapters, setChapters] = useState([]);
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('Liste des Chapitres');
    const navigate= (link)=>{
        nav(link);
    }


    const getFormationById = () => {
        axiosInstance(accessToken).get('test/api/formations/admin/detail/'+parseInt(params.id))
            .then(
                (response)=>{
                    setChapters(response.data.chapitres);
                    setLoading(false);
                }
            ).catch(
            (error)=>{
                message.error(error.response.data.error);
                setLoading(false);
            }
        )
    };
    useEffect(() => {
        getFormationById();
    }, []);


    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        { title: 'Titre',
            key: 'title',
            render:  (_, record) => (
                <>
          <span>
            {record.title}
          </span>
                </>
            ),
        },
        { title: 'Paragraphes',
            key: 'paragraphes',
            render:  (_, record) => (
                <>
          <span>
            {record.paragraphes==null ? "0" : record.paragraphes.length}
          </span>
                </>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            render:  (_, record) => {
                let result = null;
                if(record.status){
                    result = (
                        <Tag color="green" key={record.id} >
                            Activer
                        </Tag>
                    )
                }else if(!record.status){
                    result = (
                        <Tag color="volcano"  key={record.id}>
                            Desactiver
                        </Tag>
                    )
                }else {
                    result = (
                        <Tag color="volcano"  key={record.id}>
                            Unknow
                        </Tag>
                    )
                }
                return (
                    <>
                        {result}
                    </>
                )
            } ,
        },
        {
            title: 'Action',
            key: 'action',
            render:  (_, record) => {
                return  (
                    <>
                        <Space>
                            <Tooltip title={"Detail sur le chapitre"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate(ELEARNING+'/update/'+params.id+"/view/"+record.id)} >
                                    <i aria-hidden="true"><InfoCircleFilled /></i>
                                </Button>
                            </Tooltip>
                            <AddParagraph chapter={record}/>
                            <Active status={record.status} path={record.status ? "test/api/chapter/disable/":"test/api/chapter/enable/"} id={record.id}/>
                        </Space>
                    </>
                )
            }
        }
    ];


    return(
        <>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Liste des Chapitres
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <TableTemplate columns={columns} loading={loading} addBTN={<AddChapter id={parseInt(params.id)}/>} datas={chapters} />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}