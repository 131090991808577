import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {Empty, Spin} from "antd";
import {useSelector} from "react-redux";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {toastClick} from "../../../components/toast/CustomToast";
import BreadCom from "../../../components/breadCom/BreadCom";
import LoadingPage from "../../../components/loadingPage/LoadingPage";
import {LoadingContext} from "../../../context/LoadingContext";

export default function ViewDenonciation() {
    const {accessToken} = useSelector(state => state.authUser);
    const params = useParams();
    const nav = useNavigate();
    const {loading, setLoading} = useContext(LoadingContext)
    const [denonciation, setDenonciation] = useState(null);
    const [spinner, setSpinner] = useState(false);
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('View Denonciation');
    const navigation = (link) => {
        setSpinner(true);
        nav(link);
        setSpinner(false);
    }

    const breadCum = [
        {
            id:0,
            last:false,
            path:'/admin/denonciation',
            name:'Denonciations',
        },
        {
            id:1,
            last:true,
            path:'',
            name:params.id,
        }
    ];
    const getDenonciationById = () => {
        axiosInstance(accessToken).get('denonciation/find-by-id/' + parseInt(params.id))
            .then(
                (response) => {
                    setDenonciation(response.data);
                    setLoading(false);
                }
            ).catch(
            (error) => {
                toastClick(error.response.data.message,'error');
                setLoading(false);
            }
        )
    }

    useEffect(
        () => {
            setLoading(true)
            getDenonciationById();
        }, []
    )
    const returnValue = denonciation == null ? <>
        <Spin spinning={spinner}>
            <Empty/>
        </Spin>
    </> : <>
        <Spin spinning={spinner}>
            <div style={{background: '#ffffff00'}}>
                <label>
                    <strong>Auteur :</strong><span style={{
                    fontSize: '24px',
                    padding: "5px",
                    marginTop: '5px'
                }}>{denonciation.utilisateur == null ? "Anonymous" : denonciation.utilisateur.nom}</span>
                </label>
                <hr />
                <label>
                    <strong>Date :</strong><span style={{
                    fontSize: '24px',
                    padding: "5px",
                    marginTop: '5px'
                }}>{denonciation.dateDenonciation}</span>
                </label>
                <hr />
                <label>
                    <strong>Contenu :</strong><span style={{
                    fontSize: '24px',
                    padding: "5px",
                    marginTop: '5px'
                }}>{denonciation.contenu == null ? "empty" : denonciation.contenu}</span>
                </label>
            </div>
        </Spin>
    </>;

    return(
        <>
            <BreadCom name={"Denonciation info"} data={breadCum}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Detail
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        {
                                            loading ? <LoadingPage /> : <>
                                                {returnValue}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );

}