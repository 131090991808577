import React, {useEffect, useState} from "react";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {Button, Space, Tag, Tooltip} from "antd";
import TableTemplate from "../../../components/table/index";
import {useNavigate} from "react-router-dom";
import {useRole, useTitle} from "../../../components/hook/index";
import {useSelector} from "react-redux";
import AddCP from "../add";
import {Active} from "../../../components/active/index";
import {InfoCircleFilled} from "@ant-design/icons";
import BreadCom from "../../../components/breadCom/BreadCom";
import SetAdministrator from "../setUser/setAdministrator";

export default function ListCentrePartenaire(){
    const {accessToken} = useSelector(state => state.authUser);
    const [loading, setLoading] = useState(true);
    const nav = useNavigate();
    const [denonciations, setDenonciation] = useState([]);
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('Liste des Centre Partenaires');
    const navigate= (link)=>{
        nav(link);
    }

    const fetchDenonciations = () => {
        setLoading(true);
        axiosInstance(accessToken).get('api/centrepartenaire').then(
            (response)=>{
                setDenonciation(response.data);
                setLoading(false);
            }
        ).catch( (error)=>{
            setLoading(false);
        });
    }
    useEffect(() => {
        fetchDenonciations();
    }, []);
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Nom',
            key: 'nom',
            render:  (_, record) => (
                <p><strong>{record.nom==null?"":record.nom}</strong> </p>
            ),
        },
        {
            title: 'Libelle',
            key: 'libelle',
            dataIndex: 'libelle',
        },
        {
            title: 'Adresse',
            key: 'adresse',
            render:  (_, record) => (
                <>
                    <p><strong>Phone: </strong> {record.telephone}</p>
                    <p><strong>Localisation: </strong> {record.adresse}</p>
                    <p><strong>Commune: </strong> {record.nomCommune}</p>
                </>
            ),
        },
        {
            title: 'Administrateur',
            key: 'admin',
            render:  (_, record) => {
                let result = null;
                if(record.createur==null){
                    result = <p><strong>null</strong></p>
                }else {
                    result = <p><strong>{record.createur.nom}</strong></p>
                }
               return <>{result}</>
            },
        },
        {
            title: 'Status',
            key: 'status',
            render:  (_, record) => {
                let result = null;
                if(record.status === 'ACTIVE'){
                    result = (
                        <Tag color="green" key={record.id} >
                            Activer
                        </Tag>
                    )
                }else if(record.status === 'INACTIVE'){
                    result = (
                        <Tag color="volcano"  key={record.id}>
                            Desactiver
                        </Tag>
                    )
                }else {
                    result = (
                        <Tag color="volcano"  key={record.id}>
                            Unknow
                        </Tag>
                    )
                }
                return (
                    <>
                        {result}
                    </>
                )
            } ,
        },
        {
            title: 'Action',
            key: 'action',
            render:  (_, record) => {
                return  (
                    <>
                        <Space>
                            <Tooltip title={"Detail sur le Centre Partenaire"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/centrepartenaire/'+record.id)} >
                                    <i aria-hidden="true"><InfoCircleFilled /></i>
                                </Button>
                            </Tooltip>
                            <Active status={record.status} path={"api/centrepartenaire/active/"} id={record.id}/>
                            <SetAdministrator id={record.id}/>
                        </Space>
                    </>
                )
            }
        },
    ];


    return(
        <>
            <BreadCom name={"Liste des Centres Partenaires"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Liste des Centres Partenaires
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <TableTemplate columns={columns} loading={loading} addBTN={<AddCP />} datas={denonciations} />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );

}