import axiosInstance from "../configurations/axiosInstance/AxiosInstance";
import {useContext} from "react";
import {LoadingContext} from "../context/LoadingContext";
import {useSelector} from "react-redux";
import {constantes} from "./utils/Constantes";

export default function TableSearch ({pageable, setData,setPageable, url, urlSearch}){
    //const {setLoading, loading} = useContext(LoadingContext);
    const {accessToken} = useSelector(state => state.authUser);
    const handleSubmit = (e) => {
        e.preventDefault();
        const value = e.target.value.toString().trim();
        if(value.length<=2) {
            fetchOriginalData(0);
        }else {
            fetchData(0, value)
        }
    };

    const fetchData = (page=0, value='')=>{
        //setLoading(true);
        axiosInstance(accessToken).get(
            urlSearch+'/'+value+'?page='+page
        ).then((result)=> {
            setData(result.data.content);
            setPageable({
                pageable: result.data.pageable,
                last: result.data.last,
                totalElements: result.data.totalElements,
                totalPages: result.data.totalPages,
                size: result.data.size,
                number: result.data.number,
                first: result.data.first,
                numberOfElements: result.data.numberOfElements,
                empty: result.data.empty
            });
           // setLoading(false);
        }).catch((result)=> {
            setData([]);
            setPageable(constantes);
            //setLoading(false);
        });
    }
    const fetchOriginalData = (page=0)=>{
       // setLoading(true);
        axiosInstance(accessToken).get(
            url+page
        ).then((result)=> {
            setData(result.data.content);
            setPageable({
                pageable: result.data.pageable,
                last: result.data.last,
                totalElements: result.data.totalElements,
                totalPages: result.data.totalPages,
                size: result.data.size,
                number: result.data.number,
                first: result.data.first,
                numberOfElements: result.data.numberOfElements,
                empty: result.data.empty
            });
          //  setLoading(false);
        }).catch((result)=> {
            setData([]);
            setPageable(constantes);
          //  setLoading(false);
        });
    }

    return (
        <form
            className="w-full md:w-auto flex items-center gap-2 text-xs rounded-full ring-[1.5px] ring-gray-300 px-2">
            <img src="/images/search.png" alt="" width={14} height={14} />
            <input
                onKeyUp={handleSubmit}
                type="text"
                placeholder="Search..."
                className="w-[200px] p-2 bg-transparent outline-none"
            />
        </form>
    );
};