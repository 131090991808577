import {Link, Outlet, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Empty, message, Row, Spin} from "antd";
import {useSelector} from "react-redux";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import BreadCom from "../../../components/breadCom/BreadCom";

export default function ViewGroup() {
    const {accessToken} = useSelector(state => state.authUser);
    const params = useParams();
    const nav = useNavigate();
    const [groupUser, setGroupUser] = useState(null);
    const [loading, setLoading] = useState(true);
    useRole(['ROOT', 'SUPERADMIN']);
    useTitle('View Group');

    const breadCum = [
        {
            id:0,
            last:false,
            path:'/admin/group',
            name:'Groupes',
        },
        {
            id:1,
            last:true,
            path:'',
            name: 'view / '+params.id,
        }
    ];
    const navigation = (link) => {
        setLoading(true);
        nav(link);
        setLoading(false);
    }
    const getUserById = () => {
        axiosInstance(accessToken).get('api/groupes/getById/' + parseInt(params.id))
            .then(
                (response) => {
                    setGroupUser(response.data);
                    setLoading(false);
                }
            ).catch(
            (error) => {
                message.error(error.response.data.message);
                setLoading(false);
            }
        )
    }

    useEffect(
        () => {
            setLoading(true)
            getUserById();
        }, []
    )
    const returnValue = groupUser == null ? <>
        <Spin spinning={loading}>
            <Empty/>
        </Spin>
    </> : <>
        <Spin spinning={loading}>
            <div style={{background: '#ffffff00'}}>
                <label style={{marginTop: '5px'}}>
                    <Button type="primary" onClick={() => {
                        navigation('/admin/group/update/' + groupUser.id)
                    }}>Edit</Button>
                </label>
                <hr />
                <label>
                    <strong>Nom :</strong> <span
                    style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{groupUser.name}</span>
                </label>
                <hr />
                <label>
                    <strong>Libelle :</strong> <span
                    style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{groupUser.libelle}</span>
                </label>
                <hr />
                <label>
                    <strong>Description :</strong><span style={{
                    fontSize: '24px',
                    padding: "5px",
                    marginTop: '5px'
                }}>{groupUser.description == null ? "null" : groupUser.description}</span>
                </label>
                <hr />
                <Row style={{marginTop: '15px'}}>
                    <Col>
                        <Button onClick={() => {
                            navigation('/admin/group/' + groupUser.id + '/role')
                        }}> Liste des droits</Button>

                    </Col>
                </Row>

                <Outlet/>
            </div>
        </Spin>
    </>

    return(
        <>
            <BreadCom name={"Group info"} data={breadCum}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Detail
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        {returnValue}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );

}