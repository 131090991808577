import {useSelector} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {Button, Space, Tag, Tooltip} from "antd";
import {EditFilled, InfoCircleFilled} from "@ant-design/icons";
import {ResetPWD} from "../../../components/resetpwd";
import {Active} from "../../../components/active";
import {Sms} from "../../../components/sms";
import BreadCom from "../../../components/breadCom/BreadCom";
import AddUser from "../add/AddUser";
import {OneSms} from "../../../components/sms/OneSMS";
import AddUserAdmin from "../addAdmin/AddUserAdmin";
import {LoadingContext} from "../../../context/LoadingContext";
import {constantes} from "../../../components/utils/Constantes";
import LoadingPage from "../../../components/loadingPage/LoadingPage";
import ViewTable from "../../../components/table/viewTable";
import TableSearch from "../../../components/TableSearch";

export default function ListUser() {
    const {accessToken, droits} = useSelector(state => state.authUser);
    const [current, setCurrent] = useState(0);
    const {loading, setLoading} = useContext(LoadingContext)
    const [pageable, setPageable] = useState(constantes);
    const [url, setUrl] = useState('user/list/');
    const [urlSearch, setUrlSearch] = useState("user/search");
    const [data, setData] = useState([]);
    const nav = useNavigate();
    const [group, setGroup] = useState([]);
    const items = [];
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('Liste des Utilisateurs');
    const navigate= (link)=>{
        nav(link);
    }
    const fetchGroupes = () => {
        axiosInstance(accessToken).get('api/groupes').then(
            (response)=>{
                setGroup(response.data);
                group.forEach(
                    data=>{
                        items.push(
                            {
                                label: data.libelle,
                                key: data.id,
                            }
                        )
                    }
                );
                setLoading(false);
            }
        ).catch( (error)=>{
            setLoading(false);
        });
    }
    const getData = (idGroupe=0,page=0)=>{
        setLoading(true);
        axiosInstance(accessToken).get(url+idGroupe+'?page='+page).then(
            (result)=>{
                if(result.data.totalElements == 0){
                    setPageable(constantes);
                    setData([]);
                }else {
                    setData(result.data.content);
                    setPageable({
                        pageable: result.data.pageable,
                        last: result.data.last,
                        totalElements: result.data.totalElements,
                        totalPages: result.data.totalPages,
                        size: result.data.size,
                        number: result.data.number,
                        first: result.data.first,
                        numberOfElements: result.data.numberOfElements,
                        empty: result.data.empty
                    });
                }
                setLoading(false);
            }).catch(
            (error)=>{
                setData([]);
                setPageable(constantes);
                setLoading(false);
            }
        );
    }
    const fetchUsers = (idGroup, page = 0) => {
        if(idGroup ==null) {
            setCurrent(0);
        }else {
            setCurrent(idGroup.id)
        }
        getData(idGroup.id,page);
    };
    useEffect(() => {
        fetchGroupes();
    }, []);

    const breadCum = [
        {
            id:0,
            last:true,
            path:'Utilisateurs',
            name:'Utilisateurs',
        }
    ];
    const columns = [
        {
            accessor: "info",
        },
        {
            accessor: "Name",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "N°Tel",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "Email",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "Groupes",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "Status",
            className: "hidden lg:table-cell",
        },
        {
            accessor: "action",
        },
    ];
    const renderRow = (item) => {
        const classHover = "border-b border-gray-200 even:bg-slate-50 text-sm hover:bg-lamaYellow";
        let result = null;
        if(item.status === 'ACTIVE'){
            result = (
                <Tag color="green" >
                    Activer
                </Tag>
            )
        }else if(item.status === 'INACTIVE'){
            result = (
                <Tag color="volcano">
                    Desactiver
                </Tag>
            )
        }else {
            result = (
                <Tag color="volcano">
                    Unknow
                </Tag>
            )
        }
        return (
            <tr
                key={item.id}
                className={classHover}
            >
                <td className="flex items-center gap-4 p-4">
                    <div className="flex flex-col">
                        <h3 className="font-semibold">{item.id}</h3>
                        {/*<p className="text-xs text-gray-500">{item?.email}</p>*/}
                    </div>
                </td>
                <td className="hidden md:table-cell">{item.nom} {item.prenom}</td>
                <td className="hidden md:table-cell">{item.numero_telephone}</td>
                <td className="hidden md:table-cell">{item.email}</td>
                <td className="hidden md:table-cell">{item.groupe.name} ({item.groupe.libelle})</td>
                <td>
                    <div className="flex items-center gap-2">
                        {result}
                    </div>
                </td>
                <td>
                    <div className="flex items-center gap-2">
                        <Space>
                            <Tooltip title={"Detail sur l'utilisateur"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/user/'+item.id)} >
                                    <i aria-hidden="true"><InfoCircleFilled /></i>
                                </Button>
                            </Tooltip>
                            <Tooltip title={"Mise a jour de l'utilisateur"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/user/update/'+item.id)} >
                                    <i aria-hidden="true"><EditFilled /></i>
                                </Button>
                            </Tooltip>
                            <ResetPWD status={item.status} path={"user/reset-password/"} id={item.id}/>
                            <Active status={item.status} path={"user/active/"} id={item.id}/>
                            <OneSms message="SMS Individuel" num={item.numero_telephone} />
                        </Space>
                    </div>
                </td>
            </tr>
        );
    }
    const hasRole = (roles, roleName)=>{
        let hasIt=false;
        for (const rolesKey in roles) {
            if(roles[rolesKey].nom == roleName){
                hasIt=true;
            }
        }
        return hasIt;
    }

    return(
        <>
            <BreadCom name={"Liste des Utilisateur"} data={breadCum}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Liste des Utilisateurs
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        {
                                            loading ? <LoadingPage /> : <>
                                                <div>
                                                    <strong>Recherche par groupe :</strong>
                                                    <br/>
                                                    <br/>
                                                    {
                                                        group.map((groupe)=>{
                                                            if(droits.includes('SUPERADMIN')){
                                                                if(hasRole(groupe.roles, 'SUPERADMIN')){
                                                                    return null;
                                                                }else {
                                                                    return (
                                                                        <Tag title={groupe.name} style={{cursor: 'pointer'}}  color={current === groupe.id ? "green" : "blue"}  key={groupe.id} onClick={()=>fetchUsers(groupe)}>
                                                                            {groupe.name}
                                                                        </Tag>
                                                                    )
                                                                }
                                                            }else{
                                                                if(droits.includes('ROOT')) {
                                                                    if(hasRole(groupe.roles, 'SUPERADMIN') || hasRole(groupe.roles, 'ROOT')){
                                                                        return null;
                                                                    }else {
                                                                        return (
                                                                            <Tag
                                                                                title={groupe.name}
                                                                                style={{ cursor: 'pointer' }}
                                                                                color={current === groupe.id ? 'green' : 'blue'}
                                                                                key={groupe.id}
                                                                                onClick={() => fetchUsers(groupe)}
                                                                            >
                                                                                {groupe.name}
                                                                            </Tag>
                                                                        );
                                                                    }
                                                                }  else {
                                                                    if(droits.includes('ADMIN')) {
                                                                        if(hasRole(groupe.roles, 'SUPERADMIN') || hasRole(groupe.roles, 'ROOT') || hasRole(groupe.roles, 'ADMIN')){
                                                                            return null;
                                                                        }else {
                                                                            return (
                                                                                <Tag
                                                                                    title={groupe.name}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    color={current === groupe.id ? 'green' : 'blue'}
                                                                                    key={groupe.id}
                                                                                    onClick={() => fetchUsers(groupe)}
                                                                                >
                                                                                    {groupe.name}
                                                                                </Tag>
                                                                            );
                                                                        }
                                                                    }  else {
                                                                        return null;
                                                                    }
                                                                }
                                                            }
                                                            return null;

                                                        })
                                                    }
                                                    <br />
                                                    <br />
                                                    <TableSearch pageable={pageable} setData={setData} setPageable={setPageable} url={url}  urlSearch ={urlSearch}/>
                                                    <br />
                                                    {(droits.includes("SUPERADMIN") || droits.includes("ROOT")) && <AddUserAdmin/>}
                                                    <Sms message="SMS de Groupe"  listNum={data.map(value => value.numero_telephone)} />
                                                    <br />
                                                </div>
                                                <ViewTable data={data} pageable={pageable} setData={setData} url={url+current+'?page='} setPageable={setPageable} renderRow={renderRow} columns={columns} addBTN={<AddUser />} />
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );

}